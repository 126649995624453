import Vue from 'vue'
import { Toast, Dialog } from 'vant'
import Router from '@/router'
import sourceApi from '@/api/modules/source'
import CarrierType from '@/model/CarrierType'
import { exitPage } from '@/utils/bridge.js'
import { track } from '@/directives/track/send.js'
import { actions as CUCCActions } from './cucc'
import { actions as TELECOMActions } from './telecom'

const ringTone = {
  namespaced: true,
  state: () => {
    return {
      ringStatusType: null,
      carrierType: localStorage.getItem('userType') ? localStorage.getItem('userType') : CarrierType.MIGU,
      currentRingId: '',
      phoneProvince: '',
      phone: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).phone : '',
      oneClickPhone: localStorage.getItem('oneClickPhone') ? localStorage.getItem('oneClickPhone') : '',
      tipShow: false,
      sourceChange: !!sessionStorage.getItem('sourceChange'),
      sourceChangeConfirm: !!sessionStorage.getItem('sourceChangeConfirm'),
      // 搜索
      searchHotTexts: [],
      searchHintsText: '',
      // 搜索记录
      searchhistory: localStorage.getItem('histroy') ? JSON.parse(localStorage.getItem('histroy')) : [],
      searchList: [],
      // 搜索参数
      searchParams: {
        keys: '',
        start: 1,
        size: 20,
        totalCounts: 0
      },

      // 栏目数据
      categorys: window.sessionStorage.getItem('categorys')
        ? JSON.parse(window.sessionStorage.getItem('categorys'))
        : [],
      categoryPageData: {},
      categoryIdIndex: Number(window.localStorage.getItem('categoryIdIndex')) || 0,
      // 订购所需数据
      settingData: {
        videoId: '',
        productId: '',
        copyRightID: '',
        contentId: '',
        sourceType: '',
        searchkey: '',
        desc: ''
      },

      topicData: {
        headPath: '',
        introduceMidpath: '',
        backGroundColor: '',
        borderFillColor: '',
        borderLineColor: '',
        borderLineSize: '',
        buttonFillColor: '',
        buttonWordColor: '',
        buttonWord: '',
        ringContentVOList: []
      },
      changePhone: false,
      // 记录订购流程经过几个页面。
      historyPage: [],
      statusheightShow: false,
      // 是否有取消的订单
      hasCancelOrder: false,
      retentionOrderParams: {
        videoId: '',
        productId: '',
        copyRightID: '',
        contentId: '',
        sourceType: '',
        desc: '',
        phone: ''
      },
      checkPhoneCarrierType: window.localStorage.getItem('checkPhoneCarrierType')
        ? JSON.parse(window.localStorage.getItem('checkPhoneCarrierType'))
        : {},
      toggleContent: false,
      openRetention: false
    }
  },
  mutations: {
    SET_OPEN_RETENTION (state, flag) {
      state.openRetention = flag
    },
    SET_PHONE_PROVINCE (state, province) {
      state.phoneProvince = province
    },
    SET_TOGGLE_CONTENT (state, TOGGLE) {
      state.toggleContent = TOGGLE
    },

    SET_HAS_CANCEL_ORDER (state, hasCancelOrder) {
      state.hasCancelOrder = hasCancelOrder
    },
    SET_RETENTION_ORDER_PARAMS (state, data) {
      const { videoId, productId, copyRightID, sourceType, desc, phone } = data
      state.retentionOrderParams.videoId = videoId
      state.retentionOrderParams.productId = productId
      state.retentionOrderParams.copyRightID = copyRightID
      state.retentionOrderParams.contentId = videoId
      state.retentionOrderParams.sourceType = sourceType
      state.retentionOrderParams.desc = desc
      state.retentionOrderParams.phone = phone
    },
    SET_STATUSDIV (state, data) {
      state.statusheightShow = data
    },
    SET_RINGTONE_TYPE: (state, data) => {
      state.ringStatusType = data
    },
    // 运营商
    SET_CARRIER_TYPE: (state, data) => {
      state.carrierType = data
      window.localStorage.setItem('userType', data)

      if (state.phone) {
        Vue.set(state.checkPhoneCarrierType, state.phone, data)
        const obj = JSON.parse(JSON.stringify(state.checkPhoneCarrierType))
        obj[state.phone] = data
        window.localStorage.setItem('checkPhoneCarrierType', JSON.stringify(obj))
      }
    },
    // 当前播放彩铃id
    SET_CURRENT_RING_ID: (state, data) => {
      state.currentRingId = data
    },
    // 一键登录获取手机号
    SET_ONE_CLICK_PHONE: (state, data) => {
      state.oneClickPhone = data
      state.phone = data
      window.localStorage.setItem('oneClickPhone', data)
    },
    // 当前使用的手机号
    SET_PHONE: (state, data) => {
      // 如果清除了 则将手机号设置为一键登录的手机号
      if (!data && state.oneClickPhone) {
        state.phone = state.oneClickPhone
      } else {
        state.phone = data
      }
    },
    SET_TIPSHOW: (state, isShow) => {
      state.tipShow = isShow
    },
    // 底纹
    SET_HINTS_TEXT: (state, text) => {
      state.searchHintsText = text
    },
    // 搜索热词
    SET_HOT_TEXT: (state, texts) => {
      state.searchHotTexts = texts
    },
    // 删除历史记录
    CLEAR_HISTORY: (state, texts) => {
      state.searchhistory.splice(0)
      localStorage.removeItem('histroy')
    },
    // 更新搜索历史记录
    UPDATE_HISTORY: (state, searchValue) => {
      // 先找有没有存在,存在挪到第一位
      const index = state.searchhistory.findIndex((item) => item === searchValue)
      if (index !== -1) {
        const [item] = state.searchhistory.splice(index, 1)
        state.searchhistory.unshift(item)
      } else {
        // 不存在
        // 判断是否超出10个 超出十个 删除最后一个,将新的推到前面
        if (state.searchhistory.length < 10) {
          state.searchhistory.unshift(searchValue)
        } else {
          state.searchhistory.pop()
          state.searchhistory.unshift(searchValue)
        }
      }
      localStorage.setItem('histroy', JSON.stringify(state.searchhistory))
    },
    // 分类分页参数
    SET_CATEGORYS_PAGE_DATA (state, pageDatas) {
      state.categoryPageData = pageDatas
    },
    // 更细分类数据
    UPADE_PAGE_BY_CATEGPRY_ID (state, data) {
      const { categoryId, pageNum, pageSize, isEmpty } = data
      pageNum && (state.categoryPageData[categoryId].pageNum = pageNum)
      pageSize && (state.categoryPageData[categoryId].pageSize = pageSize)
      state.categoryPageData[categoryId].isEmpty = !!isEmpty
    },
    // 更新栏目分类
    SET_CATEGORYS (state, categorys) {
      state.categorys.splice(0)
      state.categorys.push(...categorys)
    },
    // 分类追加数据
    APPEND_CATEGORYS_ITEM (state, data) {
      const { id, list } = data
      const index = state.categorys.findIndex((item) => item.categoryId === id)
      const subElementArray = state.categorys[index].subElementArray
      list.forEach((item) => {
        const index = subElementArray.findIndex((i) => i.id === item.id)
        if (index === -1) {
          subElementArray.push(item)
        }
      })
    },
    // 更新分类中的其中一个分类
    SET_CATEGORYS_ITEM (state, data) {
      const { id, list } = data
      const index = state.categorys.findIndex((item) => item.categoryId === id)
      state.categorys[index].subElementArray.push(...list)
    },
    // 更新分类中的其中一个分类
    SET_CATEGORYS_RECOMMEND (state, data) {
      const { categoryTitle, list } = data
      const index = state.categorys.findIndex((item) => item.categoryTitle === categoryTitle)
      if (index !== -1) {
        state.categorys[index].subElementArray.splice(0)
        state.categorys[index].subElementArray.push(...list)
      } else {
        const item = {
          ...data,
          subElementArray: [...list]
        }
        Vue.set(state.categorys, 0, item)
      }
    },
    SET_CATEGORYS_ID_INDEX (state, categoryIdIndex) {
      state.categoryIdIndex = categoryIdIndex === -1 ? 0 : categoryIdIndex
    },
    // 更换手机号
    SET_CHANGE_PHONE (state, changePhone) {
      state.changePhone = changePhone
    },
    // 设置彩铃信息
    SET_SETTING_DESC (state, title) {
      state.settingData.desc = title
    },
    SET_SETTING_SOURCETYPE (state, sourceType) {
      state.settingData.sourceType = sourceType
    },
    SET_SETTING_VIDEOID (state, ringId) {
      state.settingData.videoId = ringId
    },
    SET_SETTING_PRODUCT_ID (state, productId) {
      state.settingData.productId = productId
    },
    SET_SETTING_CONTENT_ID (state, contentId) {
      state.settingData.contentId = contentId
    },
    SET_SETTING_CROPYRINGHT_ID (state, copyRightID) {
      state.settingData.copyRightID = copyRightID
    },
    SET_SETTING_SEARCH_KEY (state, searchkey) {
      state.settingData.searchkey = searchkey
    },
    // 记录订购流程经过那几个页面。需要go（-num）回到详情页
    SET_HISTORY_PAGE (state, list) {
      Vue.set(state, 'historyPage', list)
    },
    PUSH_HISTORY_PAGE (state, pageName) {
      state.historyPage.push(pageName)
    },
    POP_HISTORY_PAGE1 (state, pageName) {
      state.historyPage.pop()
    },
    POP_HISTORY_PAGE (state, pageName) {
      const current = state.historyPage[state.historyPage.length - 2]
      if (pageName === current) {
        state.historyPage.pop()
      }
    },
    // 回到详情页时 重置记录
    DELETE_HISTORY_PAGE (state) {
      state.historyPage.splice(0)
    },
    SET_SOURCE_CHANGE (state, sourceChange) {
      state.sourceChange = sourceChange
      window.sessionStorage.setItem('sourceChange', sourceChange)
    },

    SET_SOURCE_CHANGE_CONFIRM (state, sourceChangeConfirm) {
      state.sourceChangeConfirm = sourceChangeConfirm
      window.sessionStorage.setItem('sourceChangeConfirm', sourceChangeConfirm)
    },
    // 追加下拉刷新数据插入
    UNSHIFT_RANDOM_LIST_TO_CATEGORYS (state, data) {
      const { id, list } = data
      const index = state.categorys.findIndex((item) => item.categoryId === id)

      const subElementArray = state.categorys[index].subElementArray
      list.forEach((i) => {
        const flag = subElementArray.some((item) => item.id === i.id)
        console.log(flag, i.id)
        if (!flag) {
          subElementArray.unshift(i)
        }
      })
    }
  },
  actions: {
    ...CUCCActions,
    ...TELECOMActions,
    showTip ({ state, dispatch, rootState }, payload) {
      if (state.carrierType === CarrierType.CUCC) {
        Dialog({
          title: '业务订购申请已提交',
          message: '业务订购生效将以短信告知，生效后即可设置视频彩铃啦，请耐心等待哦~~',
          confirmButtonText: '我知道了',
          className: 'success-order-dialog',
          beforeClose: (_, done) => {
            done()
            if (Router.currentRoute.query.tabType === 'tab-home') {
              exitPage()
            }
          }
        })
      } else {
        Dialog({
          title: '设置已提交',
          message: '24小时内生效,请留意设置成功短信通知',
          confirmButtonText: '我知道了',
          className: 'success-order-dialog',
          beforeClose: (_, done) => {
            done()
            if (Router.currentRoute.query.tabType === 'tab-home') {
              exitPage()
            }
          }
        })
      }
      dispatch('loginConfirm')
    },
    // 运营商检查
    checkMobileActions ({ commit, dispatch, rootState, state }, payload) {
      dispatch('checkMobileTrack', { type: '10', res: '', isSuccess: false, carrierName: '' })
      dispatch('handleActionApi', {
        isp: '',
        token: '',
        phone: payload,
        action: '10',
        interfaceName: 'api/checkMobile',
        resCode: '',
        resMessage: ''
      })
      return new Promise((resolve, reject) => {
        sourceApi
          .checkMobile({
            phone: payload,
            pcid: Router.currentRoute.query.pcid
          })
          .then(res => {
            dispatch('handleActionApi', {
              isp: '未判断',
              token: '',
              phone: payload,
              action: '11',
              interfaceName: 'api/checkMobile',
              resCode: res.code,
              resMessage: JSON.stringify(res)
            })
            debugger
            // 异常处理
            if (res.code === '5000') {
              Toast.fail({
                message: res.message
              })
              dispatch('checkMobileTrack', {
                type: '11',
                res,
                isSuccess: res.j === '0000',
                carrierName: ''
              })
              dispatch('handleActionApi', {
                isp: '',
                token: '',
                phone: payload,
                action: '11',
                interfaceName: 'api/checkMobile',
                resCode: res.code,
                resMessage: JSON.stringify(res)
              })
              reject(res.message)
            } else {
            // 正常返回。但针对广电提醒 && (status === 1)
              const { type, detail, status, mobile } = res.data
              let message = ''
              if (status === 1) {
                message = `${detail[0] ? `${detail[0]}` : ''}-type:${type}-status:${status}-phone:${mobile}`
              } else {
                message = `${detail[0] ? `${detail[0]}省份限制` : ''},手机号:${mobile}-type:${type}-status:${status}`
              }
              dispatch('checkMobileTrack', {
                type: '11',
                res: message,
                isSuccess: (res.code === '0000'),
                carrierName: type
              })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[type],
                token: '',
                phone: payload,
                action: '11',
                interfaceName: 'api/checkMobile',
                resCode: res.code,
                resMessage: JSON.stringify(res)
              })
              if (type === CarrierType.CBN) {
                Toast.fail({
                  message: '快换移动号，广电设置不了'
                })
                reject(res.message)
              } else if (status === 1) {
                debugger
                const isSame = type === state.carrierType
                commit('SET_CARRIER_TYPE', type)
                commit('SET_PHONE_PROVINCE', detail[0])
                resolve({ type, isSame })
              } else {
                const message = `抱歉，您所在的区域${detail[0] ? `(${detail[0]})` : ''}暂时不支持使用该视频彩铃服务，敬请谅解！`
                Toast.fail({
                  message
                })
                reject(res.message)
              }
            }
          }).catch(err => {
            Toast.clear()
            dispatch('checkMobileTrack', { type: '11', res: err.message, isSuccess: false, carrierName: '' })
            dispatch('handleActionApi', {
              isp: '',
              token: '',
              phone: payload,
              action: '11',
              interfaceName: 'api/checkMobile',
              resCode: 'err',
              resMessage: `catcherr ${err.message}`
            })
          })
      })
    },
    // 确认登陆态 我知道
    loginConfirm ({ commit, state, rootState }) {
      window.localStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))
      commit('app/SET_LOGIN_STATUS', true, { root: true })
      commit('ringtone/SET_SOURCE_CHANGE_CONFIRM', '', { root: true })
      commit('ringtone/SET_SOURCE_CHANGE', '', { root: true })
      commit('ringtone/SET_TIPSHOW', false, { root: true })
      commit('ringtone/SET_HAS_CANCEL_ORDER', false, { root: true })
      // 清除切换运营标记
      // 跨浏览器标签通讯
      window.localStorage.setItem(
        'tag-fresh',
        JSON.stringify({
          phone: state.phone,
          token: rootState.app.userInfo.TOKEN,
          carrierType: state.carrierType,
          sourceChange: false,
          sourceChangeConfirm: false,
          loginStatus: rootState.app.loginStatus,
          date: new Date().getTime()
        })
      )

      window.localStorage.setItem(
        'tag-home-fresh',
        JSON.stringify({
          phone: state.phone,
          token: rootState.app.userInfo.TOKEN,
          carrierType: state.carrierType,
          sourceChange: false,
          sourceChangeConfirm: false,
          loginStatus: rootState.app.loginStatus,
          date: new Date().getTime()
        })
      )
    },
    getCarrierType ({ dispatch, commit, state, rootState }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('checkMobileActions', state.phone)
          .then(({ type }) => {
            resolve(type)
            commit('SET_CARRIER_TYPE', type)
          }).catch(err => {
            reject(err)
          })
      })
    },
    // 根据手机号token获取当前有没有功能或其他
    getRingStatus ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        sourceApi
          .ringStatus(payload)
          .then((res) => {
            commit('SET_RINGTONE_TYPE', res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getPackageList ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        sourceApi
          .packageList({ ...payload })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 热词
    getHotsText ({ commit, state, rootState }, payload) {
      return new Promise((resolve, reject) => {
        const params = {
          size: 6,
          phone: state.phone
        }
        sourceApi.searchHots(params).then((res) => {
          commit('SET_HOT_TEXT', res.data)
          resolve(res.data)
        })
      })
    },
    // 搜索底纹
    getHintsText ({ commit, state, rootState }, payload) {
      const params = {
        phone: state.phone
      }
      sourceApi.searchHints(params).then((res) => {
        if (res.data && res.data.length > 0) {
          const hints = res.data.shift()
          commit('SET_HINTS_TEXT', hints.hint)
        }
      })
    },
    trackForbidden ({ commit }, payload) {
      if (payload === '未查询到相关信息[铃音被屏蔽]') {
        // 屏蔽接口
      }
    },
    handleActionApi ({ commit, rootState }, data) {
      sourceApi.actionlogApi({
        ...data,
        pid: rootState.app.selfInfo['dev-pid'],
        uid: rootState.app.selfInfo.uid,
        cookieId: rootState.app.cookieId
      })
    },
    // 分类栏目
    getCategorysList ({ commit, state, rootState }, payload) {
      return new Promise((resolve, reject) => {
        sourceApi
          .queryCategoryVideos({ ...payload, phone: state.phone, pcid: Router.currentRoute.query.pcid })
          .then((res) => {
            res.data = res.data.map((item) => {
              const subElementArray = item.subElementArray.map((i, index) => {
                const j = {
                  ...i,
                  homePosterSrc: i.homePosterSrc ? i.homePosterSrc : i.posterSrc
                }
                j.videoSrc = j.videoSrc.replace('http:', 'https:')
                return j
              })
              return {
                ...item,
                subElementArray
              }
            })
            if (res.code === '0000') {
              commit('SET_CATEGORYS', res.data)
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 获取分类跟第一个热门分类
    getCategory ({ commit, state, rootState }, payload) {
      const { pageSize } = payload
      return new Promise((resolve, reject) => {
        sourceApi
          .queryCategory({ ...payload, phone: state.phone, pcid: Router.currentRoute.query.pcid })
          .then((res) => {
            const pageData = {}
            if (res.code === '0000') {
              res.data = res.data.map((item) => {
                const subElementArray = item.subElementArray.map((i, _index) => {
                  const j = {
                    ...i,
                    homePosterSrc: i.homePosterSrc ? i.homePosterSrc : i.posterSrc
                  }
                  j.videoSrc = j.videoSrc.replace('http:', 'https:')
                  return j
                })
                pageData[item.categoryId] = {
                  pageNum: 1,
                  pageSize: pageSize || 40,
                  isEmpty: false
                }

                return {
                  ...item,
                  subElementArray
                }
              })

              commit('SET_CATEGORYS_PAGE_DATA', pageData)
              commit('SET_CATEGORYS', res.data)
              resolve(res)
            } else {
              reject(new Error(res.message))
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getVideosByCategoryId ({ commit, state, rootState }, payload) {
      const { loadMore, pageSize, categoryId, pageNum } = payload
      const pageData = {
        pageSize: pageSize || 20,
        categoryId,
        pageNum
      }
      if (loadMore) {
        const pageNumNext = state.categoryPageData[categoryId].pageNum + 1
        const pageSizeNext = state.categoryPageData[categoryId].pageSize

        pageData.pageNum = pageNumNext
        pageData.pageSize = pageSizeNext
      }
      return new Promise((resolve, reject) => {
        sourceApi
          .queryVideoByCategoryId({ ...pageData, phone: state.phone, pcid: Router.currentRoute.query.pcid })
          .then((res) => {
            const data = res.data.colums.map((item) => {
              const j = {
                ...item,
                homePosterSrc: item.homePosterSrc ? item.homePosterSrc : item.posterSrc
              }
              j.videoSrc = j.videoSrc.replace('http:', 'https:')
              return j
            })
            if (res.code === '0000') {
              if (data.length) {
                if (loadMore) {
                  // 有数据 更新页数
                  commit('UPADE_PAGE_BY_CATEGPRY_ID', { categoryId, pageNum: pageData.pageNum })
                  commit('APPEND_CATEGORYS_ITEM', { id: payload.categoryId, list: data })
                } else {
                  commit('SET_CATEGORYS_ITEM', { id: payload.categoryId, list: data })
                }

                if (data.length < pageData.pageSize) {
                  commit('UPADE_PAGE_BY_CATEGPRY_ID', { categoryId, isEmpty: true })
                  res.isEmpty = true
                }
              } else {
                commit('UPADE_PAGE_BY_CATEGPRY_ID', { categoryId, isEmpty: true })
                res.isEmpty = true
              }
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 专题数据
    queryTopicData ({ commit, state, rootState }, payload) {
      return new Promise((resolve, reject) => {
        sourceApi
          .queryTopic({ ...payload, phone: state.phone, pcid: Router.currentRoute.query.pcid })
          .then((res) => {
            console.log(res)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    checkMobileTrack ({ commit, dispatch, rootState }, payload) {
      const { type, res, isSuccess, carrierName } = payload
      if (res && res.data && res.data.detail) {
        const [province, city] = res.data.detail
        const sensors = window.sensorsDataAnalytic201505
        sensors.registerPage({
          province,
          city
        })
      }
      const params = {
        h5_color_ring_order_api_event_type: type,
        is_success: isSuccess || false,
        error_code: !isSuccess ? res.code || res.resCode || res.resultCode : '',
        error_msg: JSON.stringify(res),
        carrier_name: ''
      }
      if (carrierName) {
        params.carrier_name = CarrierType.ENUM[carrierName]
      }
      track([{ event_id: 'h5_color_ring_order_api_event', event_param: params }])
    },
    // 设置前的判断
    settingPrepare ({ state, dispatch, rootState, commit }, payload) {
      if (Router.currentRoute.query.sourceType !== state.carrierType) {
        Toast.clear()
        sourceApi.queryRingRel({ phone: state.phone, ringId: state.settingData.videoId, pcid: Router.currentRoute.query.pcid }).then((res) => {
          console.log(res, 'debe')
          if (res.code === '0000') {
            // 获取接口查询相同彩铃名称的资源
            // 资源不一样返回之前的页面 然后切换资源
            if (res.data.telecomDetailDto && state.carrierType === CarrierType.TELECOM) {
              commit('SET_SETTING_VIDEOID', res.data.telecomDetailDto.ringId)
              commit('SET_SETTING_PRODUCT_ID', res.data.telecomDetailDto.packageId.split(',')[0])
              const videoId = res.data.telecomDetailDto.ringId
              const sourceTag = {
                isSame: false,
                sourceType: Router.currentRoute.query.sourceType,
                videoId,
                productId: res.data.telecomDetailDto.packageId.split(',')[0],
                targeType: state.carrierType,
                queryType: 1
              }
              window.localStorage.setItem('sourceTag', JSON.stringify(sourceTag))
              dispatch('appStore/changeSourceRel', { videoId, queryType: 1 }, { root: true })
              dispatch('handleOrder')
            } else if (res.data.mobileDetailDto && state.carrierType === CarrierType.MIGU) {
              commit('SET_SETTING_VIDEOID', res.data.mobileDetailDto.ringId)
              commit('SET_SETTING_CROPYRINGHT_ID', res.data.mobileDetailDto.packageId)
              const videoId = res.data.mobileDetailDto.ringId
              const sourceTag = {
                isSame: false,
                sourceType: Router.currentRoute.query.sourceType,
                videoId,
                productId: res.data.mobileDetailDto.packageId,
                targeType: state.carrierType,
                queryType: 1
              }
              window.localStorage.setItem('sourceTag', JSON.stringify(sourceTag))
              dispatch('appStore/changeSourceRel', { videoId, queryType: 1 }, { root: true })
              dispatch('handleOrder')
            } else if (res.data.unicomDetailDto && state.carrierType === CarrierType.CUCC) {
              commit('SET_SETTING_VIDEOID', res.data.unicomDetailDto.ringId)
              const videoId = res.data.unicomDetailDto.ringId
              const sourceTag = {
                isSame: false,
                sourceType: Router.currentRoute.query.sourceType,
                videoId,
                targeType: state.carrierType,
                queryType: 1
              }
              window.localStorage.setItem('sourceTag', JSON.stringify(sourceTag))
              dispatch('appStore/changeSourceRel', { videoId, queryType: 1 }, { root: true })
              dispatch('handleOrder')
            } else {
              const sourceTag = {
                isSame: false,
                sourceType: Router.currentRoute.query.sourceType,
                targeType: state.carrierType,
                phone: state.phone
              }
              window.localStorage.setItem('sourceTag', JSON.stringify(sourceTag))
              // const pageCount = state.historyPage.length ? state.historyPage.length : 1
              // Router.go(-pageCount)
              if (Router.currentRoute.query.tabType === 'tab-home') {
                // window.close()
                const query = {
                  phone: state.phone,
                  token: rootState.app.userInfo.TOKEN,
                  carrierType: state.carrierType,
                  sourceChange: true,
                  sourceChangeConfirm: true,
                  loginStatus: rootState.app.loginStatus,
                  date: new Date().getTime()
                }
                window.localStorage.setItem('tag-home-fresh', JSON.stringify(query))
                console.log('prepare', JSON.stringify(query))
                exitPage()
              } else {
                const pageCount = state.historyPage.length ? state.historyPage.length : 1
                Router.go(-pageCount)
              }
            }
          } else {
            // commit('app/SET_USERINFO',{}, { root: true })
            const sourceTag = {
              isSame: false,
              sourceType: Router.currentRoute.query.sourceType,
              targeType: state.carrierType,
              phone: state.phone
            }
            window.localStorage.setItem('sourceTag', JSON.stringify(sourceTag))
            // const pageCount = state.historyPage.length ? state.historyPage.length : 1
            // Router.go(-pageCount)
            if (Router.currentRoute.query.tabType === 'tab-home') {
              console.log(this)

              const query = {
                phone: state.phone,
                token: rootState.app.userInfo.TOKEN,
                carrierType: state.carrierType,
                sourceChange: true,
                sourceChangeConfirm: true,
                loginStatus: rootState.app.loginStatus,
                date: new Date().getTime()
              }
              window.localStorage.setItem('tag-home-fresh', JSON.stringify(query))
              console.log('prepare', JSON.stringify(query))
              exitPage()
            } else {
              const pageCount = state.historyPage.length ? state.historyPage.length : 1
              Router.go(-pageCount)
            }
          }
        })
      } else {
        dispatch('handleOrder')
      }
    },

    settingPrepareB ({ state, dispatch, rootState, commit }, payload) {
      debugger
      return new Promise((resolve, reject) => {
        // 判断当前的playurl
        let srCarrierType = Router.currentRoute.query.sourceType
        const { video } = payload
        if (video.src) {
          const isMigu = video.src.toLowerCase().includes('vod.migu.cn')
          const isCucc = video.src.toLowerCase().includes('listen.10155.com')
          const isTelecom = video.src.toLowerCase().includes('v.ctmus.cn')
          if (isMigu) {
            srCarrierType = CarrierType.MIGU
          } else if (isCucc) {
            srCarrierType = CarrierType.CUCC
          } else if (isTelecom) {
            srCarrierType = CarrierType.TELECOM
          }
        }
        debugger
        if ((Router.currentRoute.query.sourceType !== state.carrierType) || (srCarrierType !== state.carrierType)) {
          Toast.clear()
          sourceApi.queryRingRel({ phone: state.phone, ringId: state.settingData.videoId, pcid: Router.currentRoute.query.pcid }).then((res) => {
            console.log(res, 'debe')
            debugger
            if (res.code === '0000') {
              // 获取接口查询相同彩铃名称的资源
              // 资源不一样返回之前的页面 然后切换资源
              if (res.data.telecomDetailDto && ((state.carrierType === CarrierType.TELECOM))) {
                commit('SET_SETTING_VIDEOID', res.data.telecomDetailDto.ringId)
                commit('SET_SETTING_PRODUCT_ID', res.data.telecomDetailDto.packageId.split(',')[0])
                // const videoId = res.data.telecomDetailDto.ringId
                // const query = {
                //   ...Router.currentRoute.query,
                //   // videoId: state.settingData.videoId,
                //   sourceType: CarrierType.TELECOM,
                //   targeType: state.carrierType,
                //   from: 'changesource'
                // }
                // Router.replace({
                //   name: 'details',
                //   query
                // })
                dispatch('handleOrderB', payload)
                resolve(true)
              } else if (res.data.mobileDetailDto && state.carrierType === CarrierType.MIGU) {
                commit('SET_SETTING_VIDEOID', res.data.mobileDetailDto.ringId)
                commit('SET_SETTING_CROPYRINGHT_ID', res.data.mobileDetailDto.packageId)
                // const query = {
                //   ...Router.currentRoute.query,
                //   // videoId: state.settingData.videoId,
                //   sourceType: CarrierType.MIGU,
                //   targeType: state.carrierType,
                //   from: 'changesource'
                // }
                // Router.replace({
                //   name: 'details',
                //   query
                // })
                // dispatch('appStore/changeSourceRel', { videoId, queryType: 1 }, { root: true })
                //   .then(res => {
                //     dispatch('handleOrder')
                //   })
                dispatch('handleOrderB', payload)
                resolve(true)
              } else if (res.data.unicomDetailDto && ((state.carrierType === CarrierType.CUCC))) {
                commit('SET_SETTING_VIDEOID', res.data.unicomDetailDto.ringId)
                // const query = {
                //   ...Router.currentRoute.query,
                //   sourceType: CarrierType.CUCC,
                //   targeType: state.carrierType,
                //   from: 'changesource'
                // }
                // Router.replace({
                //   name: 'details',
                //   query
                // })
                dispatch('handleOrderB', payload)
                resolve(true)
              } else {
                const sourceTag = {
                  isSame: false,
                  sourceType: Router.currentRoute.query.sourceType,
                  targeType: state.carrierType
                }
                window.localStorage.setItem('sourceTag', JSON.stringify(sourceTag))
                resolve(false)
              }
            } else {
              const sourceTag = {
                isSame: false,
                sourceType: Router.currentRoute.query.sourceType ?? state.settingData.sourceType,
                targeType: state.carrierType
              }
              window.localStorage.setItem('sourceTag', JSON.stringify(sourceTag))

              resolve(false)
            }
          })
        } else {
          dispatch('handleOrderB', payload)
          resolve(true)
        }
      })
    },
    handleOrderB ({ state, dispatch }, payload) {
      switch (state.carrierType) {
        case CarrierType.MIGU: {
          dispatch('orderMIGU', { ...payload, isCurrentPageSetting: false, openOrder: true })
          break
        }
        case CarrierType.TELECOM: {
          dispatch('orderTelecom', { ...payload, isCurrentPageSetting: false, openOrder: true })
          break
        }
        case CarrierType.CUCC: {
          dispatch('orderCucc', { ...payload, isCurrentPageSetting: false, openOrder: true })
        }
      }
    },
    handleOrder ({ state, dispatch }) {
      switch (state.carrierType) {
        case CarrierType.MIGU: {
          dispatch('orderMIGU', false)
          break
        }
        case CarrierType.TELECOM: {
          dispatch('orderTelecom', false)
          break
        }
        case CarrierType.CUCC: {
          dispatch('orderCucc', false)
        }
      }
    },
    // 挽留弹窗的订购
    async retentionOrder ({ commit, state, dispatch, rootState }, payload) {
      const { videoId, productId, copyRightID, sourceType, desc } = state.retentionOrderParams
      commit('SET_SETTING_SOURCETYPE', sourceType)
      commit('SET_SETTING_VIDEOID', videoId)
      commit('SET_SETTING_DESC', desc)
      commit('SET_SETTING_PRODUCT_ID', productId)
      commit('SET_SETTING_CROPYRINGHT_ID', copyRightID)
      switch (state.carrierType) {
        case CarrierType.MIGU: {
          dispatch('orderMIGU', { isCurrentPageSetting: false, openOrder: true })
          break
        }
        case CarrierType.TELECOM: {
          dispatch('orderTelecom', { isCurrentPageSetting: false, openOrder: true, isNoComfirn: true })
          break
        }
        case CarrierType.CUCC: {
          dispatch('orderCucc', { isCurrentPageSetting: false, openOrder: true, isNoComfirn: true })
          break
        }
      }
    }

  }
}

export default ringTone
