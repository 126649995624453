import store from '@/store'
import { getObj } from '@/utils/index.js'
import crypto from '@/utils/crypto'
// 神策插件
// import pageload from 'sa-sdk-javascript/dist/web/plugin/pageload/index.es6.js'
// import pageleave from 'sa-sdk-javascript/dist/web/plugin/pageleave/index.es6.js'

const sensors = window.sensorsDataAnalytic201505
const url = decodeURIComponent(window.location.href)
const queryString = (url.split('?')[1] || '')
const query = {}
// eslint-disable-next-line array-callback-return
queryString.split('&').map(item => {
  const list = item.split('=')
  query[list[0]] = list[1]
})
// eslint-disable-next-line prefer-regex-literals
const reg = new RegExp('amp;', 'g')
const $query = JSON.parse(JSON.stringify(query).replace(reg, ''))
const puidObj = {}

if ($query.puid) {
  const puid = crypto.byteBase64($query.puid)
  const pudilist = puid.split(',')

  pudilist.forEach(element => {
    const list = element.split(':') || []
    if (list.length === 2) {
      puidObj[list[0]] = list[1]
    }
  })
}

// const pageUuid = uuidv4()

// sensors.init({
//   server_url: process.env.VUE_APP_SENSORS,
//   is_track_single_page: false, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
//   use_client_time: false,
//   send_type: 'ajax',
//   show_log: process.env.VUE_APP_SHOW_LOG === 'true',
//   heatmap: {
//     // 全埋点
//     clickmap: 'not_collect', // 不采集
//     collect_tags: {
//       div: {
//         max_level: 1 // 默认是 1，即只支持叶子 div。可配置范围是 [1, 2, 3],非该范围配置值，会被当作 1 处理。
//       },
//       li: true,
//       img: true,
//       span: true
//       // ... 其他标签
//     },
//     // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
//     scroll_notice_map: 'not_collect'
//   }

// })
// // // $webpageLoad采集
// sensors.use(pageload, { max_duration: 120 })

// sensors.setOnceProfile({ first_visit_ringback_zone_time: new Date().getTime() })

// // $webpageleave采集
// sensors.use(pageleave, {
//   custom_props: {
//     prop1: 'value1'
//   },
//   heartbeat_interval_time: 5,
//   max_duration: 5 * 24 * 60 * 60,
//   isCollectUrl: function (url) {
//     // url 为要采集页面浏览时长的页面地址。
//     return true // 采集
//     // return false; // 不采集
//   }
// })

// const pageList = []
// sensors.registerPage({
//   app_session_id: $query.app_sessionid,
//   h5_session_id: uuidv4(),
//   uid: () => {
//     return puidObj.uid || getObj(store.getters.selfInfo).uid
//   },
//   pid: () => {
//     return puidObj.pid || getObj(store.getters.selfInfo).dev_pid || $query.urlPid || '' // 设备id
//   },
//   channel_id: () => {
//     return getObj(store.getters.selfInfo).dev_channel || '1001' // 渠道
//   },
//   event_timestamp: () => {
//     return new Date().getTime()
//   },
//   app_version: () => {
//     return getObj(store.getters.selfInfo).dev_build_version || '' // 包名
//   },
//   page_view_event_id: (e) => {
//     if (['$pageview', '$WebPageLoad'].includes(e.event) && pageList.includes('$WebPageLoad') && pageList.includes('$pageview')) {
//       pageUuid = uuidv4()
//       pageList = [e.event]
//     }
//     pageList.push(e.event)
//     return pageUuid
//   },
//   record_id: () => {
//     return uuidv4()
//   },
//   $referrer: () => {
//     return window.$referrer
//   },
//   $manufacturer: () => {
//     return getObj(store.getters.selfInfo).dev_manufacturer || '' // 设备制造商
//   },
//   $model: () => {
//     return getObj(store.getters.selfInfo).dev_model || '' // 设备型号
//   }

// })

let isWebView = false

const sensors1 = {
  quick: (name, ...args) => {
    if (getObj(store.getters.selfInfo).dev_pid || isWebView) {
      sensors.quick(name, ...args)
    } else {
      setTimeout(() => {
        isWebView = true
        sensors.quick(name, ...args)
      }, 1200)
    }
  },
  track: (name, ...args) => {
    // if (getObj(store.getters.selfInfo).dev_pid || isWebView) {
    //   sensors.track(name, ...args)
    // } else {
    //   setTimeout(() => {
    //     isWebView = true
    //     sensors.track(name, ...args)
    //   }, 1200)
    // }
  }
}
export default sensors1
