const Pcid = {

  // app导流渠道号
  Apps: [
    'om01',
    'om02',
    'om06'
  ],
  FromPaly: 'om02', // App播放页-彩铃icon
  FromPalyMore: 'om06'// App播放页更多-设为彩铃
}

export default Pcid
