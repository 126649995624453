const CarrierType = {
  ENUM: {
    1: '电信',
    2: '联通',
    3: '移动'
  },
  TELECOM: '1',
  CUCC: '2',
  MIGU: '3'
}

export default CarrierType
