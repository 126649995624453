import { Toast, Dialog } from 'vant'
import Router from '@/router'
import CuccStatusType from '@/model/CuccStatusType'
import sourceApi from '@/api/modules/source'
import CarrierType from '@/model/CarrierType'
import PackageStatusType from '@/model/PackageStatusType'
import Pcid from '@/model/PcidType'
import { parseUrlParams, serializeUrlParams } from '@/utils/index'
import { track } from '@/directives/track/send.js'
const sensors = window.sensorsDataAnalytic201505
const ringTone = {
  namespaced: true,
  state: () => {
    return {
      ringStatusType: null,
      carrierType: localStorage.getItem('userInfo') ? localStorage.getItem('userType') : CarrierType.MIGU,
      currentRingId: '',
      phone: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).phone : '', //
      oneClickPhone: localStorage.getItem('oneClickPhone') ? localStorage.getItem('oneClickPhone') : '',
      tipMessage: '',
      tipMessageType: '',
      tipShow: false,
      // 栏目数据
      categorys: [],
      categoryIdIndex: Number(window.localStorage.getItem('categoryIdIndex')) || 0,
      // 订购所需数据
      settingData: {
        videoId: '',
        productId: '',
        copyRightID: '',
        contentId: '',
        sourceType: '',
        searchkey: '',
        desc: ''
      },
      showList: [],
      showCuccList: [],
      changePhone: false,
      errorMessage: ''
    }
  },
  mutations: {
    SET_ERROR_MESSAGE: (state, errorMessage) => {
      state.errorMessage = errorMessage
    },
    SET_RINGTONE_TYPE: (state, data) => {
      state.ringStatusType = data
    },
    // 运营商
    SET_CARRIER_TYPE: (state, data) => {
      state.carrierType = data
      window.localStorage.setItem('userType', data)
    },
    // 当前播放彩铃id
    SET_CURRENT_RING_ID: (state, data) => {
      state.currentRingId = data
    },
    // 一键登录获取手机号
    SET_ONE_CLICK_PHONE: (state, data) => {
      state.oneClickPhone = data
      state.phone = data
      window.localStorage.setItem('oneClickPhone', data)
    },
    // 当前使用的手机号
    SET_PHONE: (state, data) => {
      // 如果清除了 则将手机号设置为一键登录的手机号
      if (!data && state.oneClickPhone) {
        state.phone = state.oneClickPhone
      } else {
        state.phone = data
      }
    },
    SET_TIPSHOW: (state, isShow) => {
      state.tipShow = isShow
    },
    SET_TIP_MESSAGE: (state, msg) => {
      state.tipMessage = msg
    },
    SET_TIP_MESSAGE_TYPE: (state, type) => {
      state.tipMessageType = type
    },
    // 底纹
    SET_HINTS_TEXT: (state, text) => {
      state.searchHintsText = text
    },
    // 搜索热词
    SET_HOT_TEXT: (state, texts) => {
      state.searchHotTexts = texts
    },
    // 删除历史记录
    CLEAR_HISTORY: (state, texts) => {
      state.searchhistory.splice(0)
      localStorage.removeItem('histroy')
    },
    // 更新搜索历史记录
    UPDATE_HISTORY: (state, searchValue) => {
      // 先找有没有存在,存在挪到第一位
      const index = state.searchhistory.findIndex((item) => item === searchValue)
      if (index !== -1) {
        const [item] = state.searchhistory.splice(index, 1)
        state.searchhistory.unshift(item)
      } else {
        // 不存在
        // 判断是否超出10个 超出十个 删除最后一个,将新的推到前面
        if (state.searchhistory.length < 10) {
          state.searchhistory.unshift(searchValue)
        } else {
          state.searchhistory.pop()
          state.searchhistory.unshift(searchValue)
        }
      }
      localStorage.setItem('histroy', JSON.stringify(state.searchhistory))
    },
    // 更新栏目分类
    SET_CATEGORYS (state, categorys) {
      state.categorys.splice(0)
      state.categorys.push(...categorys)
    },
    SET_CATEGORYS_ID_INDEX (state, categoryIdIndex) {
      state.categoryIdIndex = categoryIdIndex
    },
    // 更换手机号
    SET_CHANGE_PHONE (state, changePhone) {
      state.changePhone = changePhone
    },
    // 设置彩铃信息
    SET_SETTING_DESC (state, desc) {
      state.settingData.desc = desc
    },
    SET_SETTING_SOURCETYPE (state, sourceType) {
      state.settingData.sourceType = sourceType
    },
    SET_SETTING_VIDEOID (state, ringId) {
      state.settingData.videoId = ringId
    },
    SET_SETTING_PRODUCT_ID (state, productId) {
      state.settingData.productId = productId
    },
    SET_SETTING_CONTENT_ID (state, contentId) {
      state.settingData.contentId = contentId
    },
    SET_SETTING_CROPYRINGHT_ID (state, copyRightID) {
      state.settingData.copyRightID = copyRightID
    },
    SET_SHOWLIST (state, showList) {
      state.showList.splice(0)
      state.showList.push(...showList)
    },
    SET_CUCC_SHOWLIST (state, showList) {
      state.showCuccList.splice(0)
      state.showCuccList.push(...showList)
    }
  },
  actions: {
    getCuccList ({ dispatch, commit, state }, payload) {
      sourceApi.queryConfig().then((res) => {
        // const id = res.data.telecom_xinxiliu_subject
        sourceApi
          .queryTopic({ subjectId: payload, phone: '13161770390', sourceType: 2 })
          .then((res) => {
            commit(
              'SET_CUCC_SHOWLIST',
              res.data.ringContentVOList.map((item) => {
                return {
                  sourceType: item.ispType,
                  id: item.ringId,
                  desc: item.ringName,
                  homePosterSrc: item.homePreviewPath,
                  title: item.ringName,
                  posterSrc: item.homePreviewPath,
                  videoSrc: item.filePath
                }
              })
            )
          })
          .catch(() => {})
      })
    },
    getTelecomList ({ dispatch, commit, state }, payload) {
      sourceApi.queryConfig().then((res) => {
        // const id = res.data.telecom_xinxiliu_subject
        sourceApi
          .queryTopic({ subjectId: payload, phone: '17310075030' })
          .then((res) => {
            commit(
              'SET_SHOWLIST',
              res.data.ringContentVOList.map((item) => {
                return {
                  sourceType: item.ispType,
                  id: item.ringId,
                  desc: item.ringName,
                  homePosterSrc: item.homePreviewPath,
                  title: item.ringName,
                  posterSrc: item.homePreviewPath,
                  videoSrc: item.filePath
                }
              })
            )
          })
          .catch(() => {})
      })
    },
    getCarrierType ({ dispatch, commit, state }, payload) {
      dispatch('checkMobileTrack', { type: '10', res: '', isSuccess: false, carrierName: '' })
      return new Promise((resolve, reject) => {
        sourceApi
          .checkMobile({
            phone: state.phone
          })
          .then((res) => {
            commit('SET_CARRIER_TYPE', res.data)
            dispatch('checkMobileTrack', {
              type: '11',
              res,
              isSuccess: res.code === '0000',
              carrierName: CarrierType.MIGU
            })
            resolve(res)
          })
          .catch((err) => {
            dispatch('checkMobileTrack', { type: '11', res: err, isSuccess: false, carrierName: '' })
            reject(err)
          })
      })
    },
    // 根据手机号token获取当前有没有功能或其他
    getRingStatus ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        sourceApi
          .ringStatus(payload)
          .then((res) => {
            commit('SET_RINGTONE_TYPE', res.data)
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getPackageList ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        sourceApi
          .packageList({ ...payload })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 热词
    getHotsText ({ commit, state, rootState }, payload) {
      const params = {
        size: 6,
        phone: state.phone
      }
      sourceApi.searchHots(params).then((res) => {
        commit('SET_HOT_TEXT', res.data)
      })
    },
    // 搜索底纹
    getHintsText ({ commit, state, rootState }, payload) {
      const params = {
        phone: state.phone
      }
      sourceApi.searchHints(params).then((res) => {
        if (res.data && res.data.length > 0) {
          const hints = res.data.shift()
          commit('SET_HINTS_TEXT', hints.hint)
        }
      })
    },
    trackForbidden ({ commit }, payload) {
      if (payload === '未查询到相关信息[铃音被屏蔽]') {
        // 屏蔽接口
      }
    },
    handleActionApi ({ commit, rootState }, data) {
      sourceApi.actionlogApi({
        ...data,
        pid: rootState.app.selfInfo['dev-pid'],
        uid: rootState.app.selfInfo.uid,
        cookieId: rootState.app.cookieId
      })
    },
    // 分类栏目
    getCategorysList ({ commit, state, rootState }, payload) {
      return new Promise((resolve, reject) => {
        sourceApi
          .queryCategoryVideos({ ...payload, phone: state.phone })
          .then((res) => {
            res.data = res.data.map((item) => {
              const subElementArray = item.subElementArray.map((i, index) => {
                return {
                  ...i,
                  homePosterSrc: i.homePosterSrc ? i.homePosterSrc : i.posterSrc
                }
              })
              return {
                ...item,
                subElementArray
              }
            })
            if (res.code === '0000') {
              commit('SET_CATEGORYS', res.data)
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    checkMobileTrack ({ commit }, payload) {
      const { type, res, isSuccess, carrierName } = payload
      const params = {
        h5_color_ring_order_api_event_type: type,
        is_success: isSuccess || false,
        error_code: !isSuccess ? res.code || res.resCode || res.resultCode : '',
        error_msg: !isSuccess ? res.message || res.resMsg || res.msg : '',
        carrier_name: ''
      }
      if (carrierName) {
        params.carrier_name = carrierName === '3' ? '中国移动' : res.data === '2' ? '中国联通' : '中国电信'
      }
      track([{ event_id: 'h5_color_ring_order_api_event', event_param: params }])
    },
    // 设置前的判断
    settingPrepareCucc ({ state, dispatch, commit }, payload) {
      Toast.loading({ forbidClick: true, duration: 1500 })
      sourceApi
        .checkMobile({
          phone: state.phone,
          pcid: Router.currentRoute.query.pcid
        })
        .then((res) => {
          commit('SET_CARRIER_TYPE', res.data.type)
          switch (res.data.type) {
            case CarrierType.TELECOM:
            case CarrierType.MIGU: {
              commit('SET_ERROR_MESSAGE', '请使用联通手机号开通')
              Toast.fail({
                message: '请使用联通手机号开通',
                forbidClick: true,
                duration: 1000
              })
              commit('SET_PHONE', '')
              break
            }
            case CarrierType.CUCC: {
              dispatch('orderCucc', false)
              break
            }
          }
        })
        .catch(() => {})
    },
    // 设置前的判断
    settingPrepare ({ state, dispatch, commit }, payload) {
      Toast.loading({ forbidClick: true, duration: 1500 })
      sourceApi
        .checkMobile({
          phone: state.phone,
          pcid: Router.currentRoute.query.pcid
        })
        .then((res) => {
          commit('SET_CARRIER_TYPE', res.data.type)
          switch (res.data.type) {
            case CarrierType.CUCC:
            case CarrierType.MIGU: {
              commit('SET_ERROR_MESSAGE', '请使用电信手机号开通')
              Toast.fail({
                message: '请使用电信手机号开通',
                forbidClick: true,
                duration: 1000
              })
              commit('SET_PHONE', '')
              break
            }
            case CarrierType.TELECOM: {
              dispatch('orderTelecom', false)
              break
            }
          }
        })
        .catch(() => {})
    },

    settingPrepareTelecom () {

    },
    // 电信订阅
    async orderTelecom ({ commit, state, dispatch, rootState }, isCurrentPageSetting) {
      // ProcessDialog()
      Toast.clear()
      Toast.loading({ forbidClick: true, duration: 1500 })
      const mobile = state.phone
      const curerntPageParams = parseUrlParams(location.href)
      curerntPageParams.videoId = state.settingData.videoId
      curerntPageParams.copyRightID = state.settingData.copyRightID
      curerntPageParams.productId = state.settingData.productId
      curerntPageParams.pcid = Router.currentRoute.query.pcid
      const returnUrl = window.location.href.split('?')[0] + '?' + serializeUrlParams(curerntPageParams)

      // await dispatch('user/getUserToken', state.phone, { root: true })
      // dispatch('checkMobileTrack', { type: '26', res: '', isSuccess: false, carrierName: CarrierType.TELECOM })
      // dispatch('handleActionApi', {
      //   isp: CarrierType.ENUM[CarrierType.TELECOM],
      //   token: rootState.app.userInfo.TOKEN,
      //   phone: rootState.app.userInfo.phone,
      //   action: '26',
      //   interfaceName: '/api/telecom/package/list',
      //   resCode: '',
      //   resMessage: ''
      // })
      dispatch('getPackageList', { mobile: state.phone, pcid: Router.currentRoute.query.pcid }).then((res) => {
        // dispatch('checkMobileTrack', { type: '27', res, isSuccess: res.code === '0000', carrierName: CarrierType.TELECOM })
        // dispatch('handleActionApi', {
        //   isp: CarrierType.ENUM[CarrierType.TELECOM],
        //   token: rootState.app.userInfo.TOKEN,
        //   phone: rootState.app.userInfo.phone,
        //   action: '27',
        //   interfaceName: '/api/telecom/package/list',
        //   resCode: res.code,
        //   resMessage: JSON.stringify(res)
        // })
        const presetProperties = sensors.getPresetProperties()
        const cookieId = presetProperties.identities.$identity_cookie_id

        let status = null
        const packageId = state.settingData.productId
        res.data?.forEach((element) => {
          if (element.package_id === packageId) {
            status = element.status
          }
        })
        const getItemaddtoneFreeParams = JSON.parse(window.sessionStorage.getItem('addtoneFreeParams')) || ''
        if (status === PackageStatusType.Subscribed) {
          // 1.一键登录的手机号是当前使用的 手机号则不需要验证码
          // 2.没确认登陆但是已经书输入过验证码
          // 3.已经确认登录 则不需要验证码
          console.log(rootState.app)
          console.log(rootState.app.loginStatus)
          console.log(rootState.app.codeValidate)

          // 未确认登录需要 输入验证码后确认
          let phoneNumber = state.phone
          let toneCode = state.settingData.videoId
          if (getItemaddtoneFreeParams && Router.currentRoute.query.order_no) {
            phoneNumber = getItemaddtoneFreeParams.phoneNumber
            toneCode = getItemaddtoneFreeParams.toneCode
            window.sessionStorage.removeItem('addtoneFreeParams')
            window.localStorage.removeItem('productId')
          }

          // dispatch('handleActionApi', {
          //   isp: CarrierType.ENUM[CarrierType.TELECOM],
          //   token: rootState.app.userInfo.TOKEN,
          //   phone: rootState.app.userInfo.phone,
          //   action: '30',
          //   interfaceName: '/telecom/addtone/free',
          //   resCode: res.code,
          //   resMessage: JSON.stringify(res)
          // })
          sourceApi
            .addtoneFree({
              phoneNumber,
              toneCode,
              promotionChannel: Router.currentRoute.query.pcid,
              pid: rootState.app.selfInfo['dev-pid'],
              uid: rootState.app.selfInfo.uid,
              cookieId: cookieId
            })
            .then((res) => {
              // dispatch('handleActionApi', {
              //   isp: CarrierType.ENUM[CarrierType.TELECOM],
              //   token: rootState.app.userInfo.TOKEN,
              //   phone: rootState.app.userInfo.phone,
              //   action: '31',
              //   interfaceName: '/telecom/addtone/free',
              //   resCode: res.code,
              //   resMessage: JSON.stringify(res)
              // })
              // track([{ event_id: 'h5_color_ring_order_result', event_param: { is_success: res.code === '0000', error_code: '', content_id: state.settingData.videoId, content_name: state.settingData.desc, error_msg: '', carrier_name: '中国电信' } }])
              Toast.clear()
              if (res.code === '0000') {
                window.localStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))

                commit('SET_PHONE', phoneNumber)
                commit('app/SET_LOGIN_STATUS', true, { root: true })
                commit('SET_ERROR_MESSAGE', '设置成功')
                Toast.success({
                  message: '设置成功',
                  forbidClick: true,
                  duration: 1500
                })
              } else {
                if (res.message === '用户不存在') {
                  commit('SET_ERROR_MESSAGE', '抱歉，您的视频彩铃功能未开通。请先在应用商店下载【爱音乐】app，然后在【我的】-【会员中心】开通功能。')
                  Dialog({
                    title: '温馨提示',
                    message:
                      '抱歉，您的视频彩铃功能未开通。请先在应用商店下载【爱音乐】app，然后在【我的】-【会员中心】开通功能。',
                    confirmButtonText: '我知道了',
                    className: 'telecom-msg sendMsg-dialog '
                  })
                  setTimeout(() => {
                    Dialog.close()
                  }, 1000)
                } else {
                  commit('SET_ERROR_MESSAGE', res.message || '设置失败')
                  Toast.fail({
                    message: res.message || '设置失败',
                    forbidClick: true,
                    duration: 1500
                  })
                }
              }
              window.localStorage.removeItem('productId')
            })
            .catch(() => {
              // dispatch('handleActionApi', {
              //   isp: CarrierType.ENUM[CarrierType.TELECOM],
              //   token: rootState.app.userInfo.TOKEN,
              //   phone: rootState.app.userInfo.phone,
              //   action: '31',
              //   interfaceName: '/telecom/addtone/free',
              //   resCode: 'err',
              //   resMessage: JSON.stringify(err)
              // })
            })
        } else {
          if (
            isCurrentPageSetting &&
            !state.sourceChange &&
            !!(window.KuYinExt && window.KuYinExt.requestAuthorizationAs)
          ) {
            const query = {
              ...Router.currentRoute.query,
              ...state.settingData
            }
            if (Number(Router.currentRoute.query.status_height) > 0) {
              query.status_height = Router.currentRoute.query.status_height
            }
            Router.replace({
              name: 'confirm-phone',
              query,
              params: {
                isPush: true
              }
            })
          } else {
            // 没有定
            // // H5 计费下单发起接口（一键开户）
            // dispatch('handleActionApi', {
            //   isp: CarrierType.ENUM[CarrierType.TELECOM],
            //   token: rootState.app.userInfo.TOKEN,
            //   phone: rootState.app.userInfo.phone,
            //   action: '28',
            //   interfaceName: '/api/telecom/openOrder',
            //   resCode: '',
            //   resMessage: ''
            // })

            // dispatch('checkMobileTrack', { type: '28', res: '', isSuccess: false, carrierName: CarrierType.TELECOM })
            sourceApi
              .openOrder({
                mobile,
                returnUrl,
                pcid: Router.currentRoute.query.pcid,
                productId: state.settingData.productId,
                verifyType: 1,
                isMessage: 1
              })
              .then((res) => {
                // dispatch('checkMobileTrack', { type: '29', res, isSuccess: res.resCode === '0000', carrierName: CarrierType.TELECOM })
                // dispatch('handleActionApi', {
                //   isp: CarrierType.ENUM[CarrierType.TELECOM],
                //   token: rootState.app.userInfo.TOKEN,
                //   phone: rootState.app.userInfo.phone,
                //   action: '29',
                //   interfaceName: '/api/telecom/openOrder',
                //   resCode: res.code,
                //   resMessage: JSON.stringify(res)
                // })

                Toast.clear()
                if (res.code === '0000') {
                  sourceApi.channelAdd({
                    mobile: state.phone,
                    type: ~~CarrierType.TELECOM,
                    promotionChannel: Router.currentRoute.query.pcid, // 推广渠道
                    pid: rootState.app.selfInfo['dev-pid'],
                    uid: rootState.app.selfInfo.uid,
                    cookieId: cookieId
                  })
                  if (res.data) {
                    // track([{ event_id: 'h5_color_ring_link_order_page_success' }])
                    window.sessionStorage.setItem(
                      'addtoneFreeParams',
                      JSON.stringify({
                        phoneNumber: mobile,
                        toneCode: state.settingData.videoId,
                        contentId: state.settingData.videoId,
                        contentName: state.settingData.desc
                      })
                    )

                    window.localStorage.setItem('productId', state.settingData.productId)
                    // 先存到session中方便会挑时恢复状态
                    window.sessionStorage.setItem('carrierType', state.carrierType)
                    // 跳转之前先把token phone信息 先存到session中方便会挑时恢复状态
                    window.localStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))
                    window.sessionStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))
                    // 储存历史页面记录
                    window.sessionStorage.setItem('historyPage', JSON.stringify(state.historyPage))
                    if (Router.currentRoute.query.searchkey) {
                      window.sessionStorage.setItem('searchList', JSON.stringify(rootState.ringtone.searchList))
                    }
                    // 综搜跟播放页使用过来的
                    if (Pcid.Apps.includes(Router.currentRoute.query.pcid)) {
                      window.sessionStorage.setItem(
                        'importTypeDataList',
                        JSON.stringify(rootState.appStore.importTypeDataList)
                      )
                    }

                    // 切换资源的内容
                    if (Router.currentRoute.query.from === 'changesource') {
                      window.sessionStorage.setItem(
                        'changeSourceList',
                        JSON.stringify(rootState.appStore.changeSourceList)
                      )
                    }
                    // 二次确认开通
                    window.location.href = res.data.feeUrl
                  } else {
                    Toast.fail(res.message)
                  }
                } else if (res.code === '3001') {
                  Toast.fail('登录过期，请重新登录')
                  // this.loginDialog = true
                } else if (res.code === '5000') {
                  if (getItemaddtoneFreeParams && Router.currentRoute.query.order_no) {
                    window.sessionStorage.removeItem('addtoneFreeParams')
                    window.localStorage.removeItem('productId')
                  } else {
                    if (!rootState.app.loginStatus) {
                      commit('app/SET_USERINFO', {}, { root: true })
                      commit('SET_PHONE', '')
                    }
                  }
                  Toast.fail(res.message)
                } else {
                  Toast.fail(res.message)
                  if (!rootState.app.loginStatus) {
                    commit('app/SET_USERINFO', {}, { root: true })
                    commit('SET_PHONE', '')
                  }
                }
              })
              .catch(() => {
                Toast.clear()
                // dispatch('handleActionApi', {
                //   isp: CarrierType.ENUM[CarrierType.TELECOM],
                //   token: rootState.app.userInfo.TOKEN,
                //   phone: rootState.app.userInfo.phone,
                //   action: '29',
                //   interfaceName: '/api/telecom/openOrder',
                //   resCode: 'err',
                //   resMessage: JSON.stringify(err)
                // })
              })
          }
        }
      })
    },
    // 联通订购
    async orderCucc ({ commit, state, dispatch, rootState, getters }, payload) {
      Toast.loading({ forbidClick: true, duration: 1500 })

      const curerntPageParams = {}
      curerntPageParams.videoId = state.settingData.videoId
      curerntPageParams.finsh = true
      const returnUrl = window.location.href.split('?')[0] + '?' + serializeUrlParams(curerntPageParams)
      const presetProperties = sensors.getPresetProperties()
      const cookieId = presetProperties.identities.$identity_cookie_id

      console.log(returnUrl.length)
      await dispatch('user/getUserToken', state.phone, { root: true })
      // 查询是否订购
      dispatch('checkMobileTrack', { type: '35', res: '', isSuccess: false, carrierName: CarrierType.CUCC })
      dispatch('handleActionApi', {
        isp: CarrierType.ENUM[CarrierType.CUCC],
        token: rootState.app.userInfo.TOKEN,
        phone: rootState.app.userInfo.phone,
        action: '35',
        interfaceName: '/qrySubedProductsNoToken',
        resCode: '',
        resMessage: ''
      })
      sourceApi.qrySubedProductsNoToken({ promotionChannel: Router.currentRoute.query.pcid, phone: state.phone }).then((res) => {
        dispatch('checkMobileTrack', {
          type: '36',
          res,
          isSuccess: res.code === '0000',
          carrierName: CarrierType.CUCC
        })
        dispatch('handleActionApi', {
          isp: CarrierType.ENUM[CarrierType.CUCC],
          token: rootState.app.userInfo.TOKEN,
          phone: rootState.app.userInfo.phone,
          action: '36',
          interfaceName: '/qrySubedProductsNoToken',
          resCode: '',
          resMessage: ''
        })
        // 没开通
        debugger
        if (res.data !== CuccStatusType.OrderFinsh) {
          // window.localStorage.setItem('return', returnUrl)
          sourceApi
            .onePointProductMon({ phone: state.phone, redirectUrl: returnUrl, ringId: state.settingData.videoId, promotionChannel: Router.currentRoute.query.pcid })
            .then((resO) => {
              // 开通
              dispatch('checkMobileTrack', {
                type: '38',
                res: resO,
                isSuccess: resO.code === '0000',
                carrierName: CarrierType.CUCC
              })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.CUCC],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '38',
                interfaceName: '/onePointProductMon',
                resCode: resO.code,
                resMessage: JSON.stringify(resO)
              })

              Toast.clear()
              if (resO.code === '0000') {
                sourceApi.channelAdd({
                  mobile: state.phone,
                  type: ~~CarrierType.CUCC,
                  promotionChannel: Router.currentRoute.query.pcid, // 推广渠道
                  pid: rootState.app.selfInfo['dev-pid'],
                  uid: rootState.app.selfInfo.uid,
                  cookieId: cookieId
                })
                if (resO.data) {
                  window.location.href = resO.data.url
                }
              } else {
                commit('SET_ERROR_MESSAGE', resO.message)
                Toast.fail(resO.message)
              }
            })
            .catch((err) => {
              dispatch('checkMobileTrack', {
                type: '38',
                res: JSON.stringify(err),
                isSuccess: false,
                carrierName: CarrierType.CUCC
              })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.CUCC],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '38',
                interfaceName: '/onePointProductMon',
                resCode: 'err',
                resMessage: JSON.stringify(err)
              })
            })
        } else {
        // 开通了
          sourceApi
            .settingRingOnePointMon2({
              phone: state.phone,
              ringId: state.settingData.videoId,
              promotionChannel: Router.currentRoute.query.pcid,
              pid: rootState.app.selfInfo['dev-pid'],
              uid: rootState.app.selfInfo.uid,
              cookieId: cookieId
            })
            .then((res) => {
              dispatch('checkMobileTrack', {
                type: '30',
                res,
                isSuccess: res.code === '0000',
                carrierName: CarrierType.TELECOM
              })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.CUCC],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '30',
                interfaceName: '/settingRingOnePointMon',
                resCode: res.code,
                resMessage: JSON.stringify(res)
              })
              Toast.clear()
              if (res.code === '0000') {
                window.localStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))

                commit('app/SET_LOGIN_STATUS', true, { root: true })
                dispatch('loginConfirm')
                commit('SET_ERROR_MESSAGE', '设置成功')
                Toast.success({
                  message: '设置成功',
                  forbidClick: true,
                  duration: 1500,
                  onClose: () => {

                  }
                })
              } else {
                commit('SET_ERROR_MESSAGE', res.message || '设置失败')
                Toast.fail({
                  message: res.message || '设置失败',
                  forbidClick: true,
                  duration: 1500
                })
              }
              window.localStorage.removeItem('productId')
            })
            .catch((err) => {
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.CUCC],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '31',
                interfaceName: '/settingRingOnePointMon',
                resCode: 'err',
                resMessage: JSON.stringify(err)
              })
            })
        }
      })
    }
    // dispatch('orderCucc', false)

  }
}

export default ringTone
