import sourceApi from '@/api/modules/source'
import { savePageState } from '@/utils/bridge.js'
import Router from '@/router'

const state = {
  searchList: window.sessionStorage.getItem('searchList')
    ? JSON.parse(window.sessionStorage.getItem('searchList'))
    : [],
  // 搜索参数
  searchParams: window.sessionStorage.getItem('searchParams')
    ? JSON.parse(window.sessionStorage.getItem('searchParams'))
    : {
        keys: '',
        start: 1,
        size: 20,
        totalCounts: 0
      },

  // 导流浏览的数据
  importTypeDataList: window.sessionStorage.getItem('importTypeDataList')
    ? JSON.parse(window.sessionStorage.getItem('importTypeDataList'))
    : [],
  // 导流搜索参数
  importSearchParams: {
    ringId: '',
    songName: '',
    actorName: '',
    start: 1,
    size: 5,
    totalCounts: 0
  },
  changeSourceList: window.sessionStorage.getItem('changeSourceList')
    ? JSON.parse(window.sessionStorage.getItem('changeSourceList'))
    : [],
  topicData: {
    name: '',
    headPath: '',
    introduceMidpath: '',
    backGroundColor: '',
    borderFillColor: '',
    borderLineColor: '',
    borderLineSize: '',
    buttonFillColor: '',
    buttonWordColor: '',
    subjectType: '',
    contentHeader: '',
    contentMiddle: '',
    contentBottom: '',
    buttonWord: '',
    ringContentVOList: []
  },
  // 随机彩铃列表数据
  randomList: window.sessionStorage.getItem('randomList')
    ? JSON.parse(window.sessionStorage.getItem('randomList'))
    : [],
  tabHomeListQuery: window.sessionStorage.getItem('tabHomeListQuery')
    ? JSON.parse(window.sessionStorage.getItem('tabHomeListQuery'))
    : {
        pageNum: 1,
        pageSize: 20,
        isNomore: false,
        isEmpty: false,
        loadMoreLoading: false
      },
  tabHomeList: window.sessionStorage.getItem('tabHomeList')
    ? JSON.parse(window.sessionStorage.getItem('tabHomeList'))
    : [],
  // 播放页样式
  showDetailStyle: 'a'
}

const mutations = {
  SET_DETAIL_STYLE (state, showDetailStyle) {
    state.showDetailStyle = showDetailStyle
  },
  // 设置搜索参数
  SET_SERACH_PARAMS (state, params) {
    console.log(params, '设置搜索参数')
    const { keys, start, size, totalCounts } = params
    state.searchParams.keys = keys
    state.searchParams.start = start
    state.searchParams.size = size
    state.searchParams.totalCounts = totalCounts
  },
  // 设置搜索参数pagesize
  ADD_SERACH_CURRENT_PAGE (state) {
    state.searchParams.start++
  },
  // 设置搜索参数pagesize
  SET_SERACH_TOTAL (state, total) {
    state.searchParams.totalCounts = total
  },
  // 设置搜索参数pagesize
  SET_SERACH_CURRENT_PAGE (state, start) {
    state.searchParams.start = start
  },
  // 设置搜索参数keys
  SET_SERACH_KEY (state, keys) {
    state.searchParams.keys = keys
  },
  // 搜索列表
  SET_SEARCH_LIST (state, searchList) {
    state.searchList = searchList
  },
  DELETE_SEARCH_LIST (state) {
    state.searchList.splice(0)
    console.log(state.searchList, 'search')
  },
  // 追加列表
  APPEND_SEARCH_LIST (state, searchList) {
    state.searchList.push(...searchList)
  },
  // 导流 数据的接口
  SET_IMPORT_SERACH_PARAMS (state, params) {
    const { ringId, start, size, songName, actorName, totalCounts } = params

    state.importSearchParams.songName = songName
    state.importSearchParams.actorName = actorName
    state.importSearchParams.ringId = ringId
    start && (state.importSearchParams.start = start)
    size && (state.importSearchParams.size = size)
    totalCounts && (state.importSearchParams.totalCounts = totalCounts)
  },
  // 设置导流搜索参数pagesize
  ADD_IMPORT_SERACH_CURRENT_PAGE (state) {
    state.importSearchParams.start++
  },
  // 设置导流搜索参数pagesize
  SET_IMPORT_SERACH_TOTAL (state, total) {
    state.importSearchParams.totalCounts = total
  },
  // 设置导流搜索参数pagesize
  SET_IMPORT_SERACH_CURRENT_PAGE (state, start) {
    state.importSearchParams.start = start
  },
  // 追加导流列表
  APPEND_IMPORT_SEARCH_LIST (state, searchList) {
    console.log(searchList, 'append')
    state.importTypeDataList.push(...searchList)
  },

  // 导流搜索列表
  DELETE_IMPORT_SEARCH_LIST (state, searchList) {
    state.importTypeDataList.splice(0)
  },
  // 导流搜索列表
  SET_IMPORT_SEARCH_LIST (state, searchList) {
    state.importTypeDataList = searchList
  },
  // 切换资源存储
  SET_CHANGE_SOURCE_LIST (state, list) {
    state.changeSourceList = list
  },
  // 专题页测试
  SET_TOPIC_CONFIG (state, data) {
    const {
      headPath,
      introduceMidpath,
      backGroundColor,
      borderFillColor,
      borderLineColor,
      borderLineSize,
      buttonWordColor,
      buttonFillColor,
      buttonWord,
      ringContentVOList,
      name,
      subjectType,
      contentHeader,
      contentMiddle,
      contentBottom
    } = data
    state.topicData.headPath = headPath
    state.topicData.introduceMidpath = introduceMidpath
    state.topicData.backGroundColor = backGroundColor
    state.topicData.borderFillColor = borderFillColor
    state.topicData.borderLineColor = borderLineColor
    state.topicData.borderLineSize = borderLineSize
    state.topicData.buttonFillColor = buttonFillColor
    state.topicData.buttonWordColor = buttonWordColor
    state.topicData.buttonWord = buttonWord
    state.topicData.name = name
    state.topicData.ringContentVOList.splice(0)
    state.topicData.subjectType = subjectType
    state.topicData.contentHeader = contentHeader
    state.topicData.contentMiddle = contentMiddle
    state.topicData.contentBottom = contentBottom

    ringContentVOList.forEach((item) => {
      const tem = {
        ...item,
        videoSrc: item.filePath,
        posterSrc: item.previewPath,
        homePosterSrc: item.homePreviewPath,
        sourceType: item.ispType.toString(),
        id: item.ringId,
        desc: item.ringName

      }
      state.topicData.ringContentVOList.push(tem)
    })
  },
  // 下拉刷新数据插入
  SET_RANDOM_LIST (state, data) {
    state.randomList = data
  },
  // 追加下拉刷新数据插入
  UNSHIFT_RANDOM_LIST (state, list) {
    list.forEach((i) => {
      const flag = state.randomList.some((item) => item.id === i.id)
      console.log(flag, i.id)
      if (!flag) {
        state.randomList.unshift(i)
      }
    })
  },
  // 二级频道首页数据
  SET_TAB_HOME_LIST (state, list) {
    state.tabHomeList = list
  },
  APPEND_TAB_HOME_LIST (state, searchList) {
    searchList.forEach((item) => {
      const flag = state.tabHomeList.some(i => item.id === i.id)
      if (!flag) {
        state.tabHomeList.push(item)
      }
    })
  },
  DELETE_TAB_HOME_LIST (state) {
    state.tabHomeList.splice(0)
  },
  SET_TAB_HOME_EMPTY (state, isEmpty) {
    state.tabHomeListQuery.isEmpty = isEmpty
  },
  SET_TAB_HOME_PARAMS (state, params) {
    console.log(params, '设置搜索参数')
    const { pageNum, pageSize } = params
    state.tabHomeListQuery.pageNum = pageNum
    state.tabHomeListQuery.pageSize = pageSize
  },
  ADD_TAB_HOME_CURRENT_PAGE (state) {
    state.tabHomeListQuery.pageNum++
  },
  SET_TAB_HOME_TOTAL (state, total) {
    state.tabHomeListQuery.totalCounts = total
  },
  SET_TAB_HOME_CURRENT_PAGE (state, pageNum) {
    state.tabHomeListQuery.pageNum = pageNum
  },
  SET_TAB_HOME_CURRENT_PAGE_SIZE (state, pageSize) {
    state.tabHomeListQuery.pageSize = pageSize
  }
}
const actions = {
  // 样式
  getDetailStyle ({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      sourceApi
        .queryConfig()
        .then((res) => {
          if (res.code === '0000') {
            if (res.data.detailStyleA) {
              resolve(res.data.detailStyleA)
              const tem = JSON.stringify({ ...state })
              const temData = JSON.parse(tem)
              const savedata = JSON.stringify(temData)
              savePageState(savedata)
            }
          } else {
            resolve('a')
            commit('SET_DETAIL_STYLE', 'a')
          }
        })
        .catch(() => {
          resolve('a')
          commit('SET_DETAIL_STYLE', 'a')
        })
    })
  },
  updateStoreData ({ commit, state }, data) {
    const { type, payload } = data
    commit(type, payload)

    const tem = JSON.stringify({ ...state })
    const temData = JSON.parse(tem)
    temData.searchList = temData.searchList.map((item) => {
      return {
        ...item,
        homePosterSrc: encodeURI(item.homePosterSrc),
        posterSrc: encodeURI(item.posterSrc),
        videoSrc: encodeURI(item.videoSrc)
      }
    })
    temData.importTypeDataList = temData.importTypeDataList.map((item) => {
      return {
        ...item,
        homePosterSrc: encodeURI(item.homePosterSrc),
        posterSrc: encodeURI(item.posterSrc),
        videoSrc: encodeURI(item.videoSrc)
      }
    })

    temData.topicData.ringContentVOList = temData.topicData.ringContentVOList.map((item) => {
      return {
        ...item,
        homePosterSrc: encodeURI(item.homePosterSrc),
        posterSrc: encodeURI(item.posterSrc),
        videoSrc: encodeURI(item.videoSrc)
      }
    })

    temData.randomList = temData.randomList.map((item) => {
      return {
        ...item,
        homePosterSrc: encodeURI(item.homePosterSrc),
        posterSrc: encodeURI(item.posterSrc),
        videoSrc: encodeURI(item.videoSrc)
      }
    })
    const savedata = JSON.stringify(temData)
    if (!data.payload) {
      // window.bridge.savePageData('{}')
      savePageState({})
    } else {
      // window.bridge.savePageData(savedata)
      savePageState(savedata)
    }
  },
  // 搜索
  getSearchPages ({ commit, state, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        ...state.searchParams,
        phone: rootState.ringtone.phone
      }
      sourceApi
        .searchPages({ ...params, pcid: Router.currentRoute.query.pcid })
        .then((res) => {
          const poster = res.data.poster // "poster": "totalCounts:16, page:3, size:10, currentNum:0",
          const reg = /totalCounts:(\d+)/
          const arr = poster.match(reg)
          let totalCounts = state.searchParams.totalCounts
          if (arr) {
            totalCounts = ~~arr[1] ?? 0
            commit('SET_SERACH_TOTAL', totalCounts)
            const tem = JSON.stringify({ ...state })
            const temData = JSON.parse(tem)
            temData.searchList = temData.searchList.map((item) => {
              return {
                ...item,
                homePosterSrc: decodeURI(item.homePosterSrc),
                posterSrc: decodeURI(item.posterSrc),
                videoSrc: decodeURI(item.videoSrc)
              }
            })
            temData.importTypeDataList = temData.importTypeDataList.map((item) => {
              return {
                ...item,
                homePosterSrc: decodeURI(item.homePosterSrc),
                posterSrc: decodeURI(item.posterSrc),
                videoSrc: decodeURI(item.videoSrc)
              }
            })
            const savedata = JSON.stringify(temData)
            savePageState(savedata)
          }
          res.totalCounts = totalCounts
          res.data.colums = res.data.colums.map((item) => {
            return {
              ...item,
              homePosterSrc: item.homePosterSrc ? item.homePosterSrc : item.posterSrc
            }
          })
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getRintoneById ({ commit, state, rootState, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        videoId: payload.videoId,
        pcid: Router.currentRoute.query.pcid
      }
      sourceApi.searchDetailById(params).then((res) => {
        // 如果运营商一样则添加否则不添加
        if (res.data) {
          if (rootState.ringtone.carrierType === res.data.sourceType || !rootState.ringtone.phone) {
            commit('DELETE_IMPORT_SEARCH_LIST')
            commit('APPEND_IMPORT_SEARCH_LIST', [res.data])
          } else {
            commit('DELETE_IMPORT_SEARCH_LIST')
          }
        }
        if (res.code !== '5000') {
          resolve(res)
        } else {
          reject(res)
        }
      })
    })
  },
  // 切换资源调用接口
  changeSourceRel ({ commit, state, rootState, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        ...payload,
        phone: rootState.ringtone.phone,
        pcid: Router.currentRoute.query.pcid
      }
      sourceApi.searchRel(params).then((res) => {
        if (res.code === '0000') {
          dispatch('updateStoreData', {
            type: 'SET_CHANGE_SOURCE_LIST',
            payload: res.data.list
          })
        }
        resolve(res)
      })
    })
  },
  // 切换资源调用接口
  changeSource ({ commit, state, rootState, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        ...payload,
        phone: rootState.ringtone.phone,
        pcid: Router.currentRoute.query.pcid
      }
      sourceApi.searchRel(params).then((res) => {
        if (res.code === '0000') {
          dispatch('updateStoreData', {
            type: 'SET_CHANGE_SOURCE_LIST',
            payload: res.data.list
          })
        }
        resolve(res)
      })
    })
  },
  // 随机资源
  queryRandom ({ commit, state, rootState, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        ...payload,
        phone: rootState.ringtone.phone,
        pcid: Router.currentRoute.query.pcid
      }
      sourceApi.queryRandom(params).then((res) => {
        dispatch('updateStoreData', {
          type: 'UNSHIFT_RANDOM_LIST',
          payload: res
        })
        resolve(res)
      })
    })
  },
  importSearchRel ({ commit, state, rootState, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const params = {
        ...payload,
        phone: rootState.ringtone.phone,
        pcid: Router.currentRoute.query.pcid
      }
      sourceApi
        .searchRel(params)
        .then((res) => {
          commit('SET_IMPORT_SERACH_TOTAL', res.data.totalCount)
          const tem = JSON.stringify({ ...state })
          const temData = JSON.parse(tem)
          temData.searchList = temData.importTypeDataList.map((item) => {
            return {
              ...item,
              homePosterSrc: decodeURI(item.homePosterSrc),
              posterSrc: decodeURI(item.posterSrc),
              videoSrc: decodeURI(item.videoSrc)
            }
          })
          temData.importTypeDataList = temData.importTypeDataList.map((item) => {
            return {
              ...item,
              homePosterSrc: decodeURI(item.homePosterSrc),
              posterSrc: decodeURI(item.posterSrc),
              videoSrc: decodeURI(item.videoSrc)
            }
          })
          const savedata = JSON.stringify(temData)
          savePageState(savedata)
          res.data.list = res.data.list.map((item) => {
            return {
              ...item,
              homePosterSrc: item.homePosterSrc ? item.homePosterSrc : item.posterSrc
            }
          })
          console.log(res.data.list, 'jinla')
          dispatch('updateStoreData', {
            type: 'APPEND_IMPORT_SEARCH_LIST',
            payload: res.data.list
          })

          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // 二级tab数据
  queryTabHomeData ({ commit, state, rootState, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      sourceApi
        .queryTopic({ phone: rootState.ringtone.phone, ...payload, ...state.tabHomeListQuery, pcid: Router.currentRoute.query.pcid })
        .then((res) => {
          console.log(res, 'SET_TOPIC_CONFIG')
          if (res.code !== '0000') {
            reject(res.message)
            return
          }
          const { ringContentVOList } = res.data
          if (payload.loadMore) {
            dispatch('updateStoreData', {
              type: 'APPEND_TAB_HOME_LIST',
              payload: ringContentVOList.map((item) => {
                return {
                  ...item,
                  videoSrc: item.filePath,
                  posterSrc: item.previewPath,
                  homePosterSrc: item.homePreviewPath,
                  sourceType: item.ispType.toString(),
                  id: item.ringId,
                  desc: item.ringName
                }
              })
            })
          } else {
            if (ringContentVOList.length === 0) {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('暂无资源')
            }
            commit('DELETE_TAB_HOME_LIST')
            dispatch('updateStoreData', {
              type: 'SET_TAB_HOME_LIST',
              payload: ringContentVOList.map((item) => {
                return {
                  ...item,
                  videoSrc: item.filePath,
                  posterSrc: item.previewPath,
                  homePosterSrc: item.homePreviewPath,
                  sourceType: item.ispType.toString(),
                  id: item.ringId,
                  desc: item.ringName
                }
              })

            })
          }

          if ((ringContentVOList.length === 0) || (ringContentVOList.length < state.tabHomeListQuery.pageSize)) {
            state.tabHomeListQuery.isEmpty = true
            res.isEmpty = true
          }
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // 专题数据
  queryTopicData ({ commit, state, rootState, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      sourceApi
        .queryTopic({ ...payload, phone: rootState.ringtone.phone, pcid: Router.currentRoute.query.pcid })
        .then((res) => {
          console.log(res, 'SET_TOPIC_CONFIG')
          const regex = /800\*800/

          // res.data.headPath =  'https://test-static5.allsaints.top/2024/08/13/1723531030530331_800*800.webp'

          // res.data.contentHeader =  'https://test-static5.allsaints.top/2024/08/13/1723531090246626_800*800.webp'
          // res.data.contentMiddle = 'https://test-static5.allsaints.top/2024/08/13/1723531125479457_800*800.webp'
          // res.data.contentBottom =  'https://test-static5.allsaints.top/2024/08/13/1723531167973511_800*800.webp'
          if (res.data.headPath && res.data.headPath.indexOf('webp') !== -1) {
            res.data.headPath = res.data.headPath.replace(regex, '1200*1200')
          }
          if (res.data.contentBottom && res.data.contentBottom.indexOf('webp') !== -1) {
            res.data.contentBottom = res.data.contentBottom.replace(regex, '1200*1200')
          }
          if (res.data.contentHeader && res.data.contentHeader.indexOf('webp') !== -1) {
            res.data.contentHeader = res.data.contentHeader.replace(regex, '1200*1200')
          }
          if (res.data.contentMiddle && res.data.contentMiddle.indexOf('webp') !== -1) {
            res.data.contentMiddle = res.data.contentMiddle.replace(regex, '1200*1200')
          }

          dispatch('updateStoreData', {
            type: 'SET_TOPIC_CONFIG',
            payload: res.data
          })

          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
