import { getAvatarUrl } from '@/utils/bridge.js'
import userApi from '@/api/modules/user'

const user = {
  namespaced: true,
  state: () => {
    return {
      // 头像
      avatar: ''
    }
  },
  mutations: {
    SET_AVATAR: (state, data) => {
      state.avatar = data
    }
  },
  actions: {
    // 获取用户头像
    getAvatar ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getAvatarUrl(payload)
          .then(data => {
            const avatarUrl = JSON.parse(data).avatarUrl ? JSON.parse(data).avatarUrl : ''
            commit('SET_AVATAR', avatarUrl)
            resolve(avatarUrl)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getUserToken ({ commit }, phone) {
      return new Promise((resolve, reject) => {
        userApi.newLogin({ phone }).then(res => {
          commit('app/SET_USERINFO', { phone, TOKEN: res.data.token }, { root: true })
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    }
  }
}

export default user
