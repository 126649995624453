import Vue from 'vue'
import VueRouter from 'vue-router'
import HeaderType from '@/model/HeaderType'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/ringZone'
  },
  {
    path: '/purchased',
    name: 'tab-details',
    component: () => import(/* webpackChunkName:"tab-purchased" */'@/views/purchased'),
    meta: { title: '视频详情', name: '视频彩铃详情页', pageIndex: 5 }
  },
  {
    path: '/purchased-cucc',
    name: 'purchased-cucc',
    component: () => import(/* webpackChunkName:"tab-purchased" */'@/views/purchased-cucc/index'),
    meta: { title: '视频详情', name: '视频彩铃详情页', pageIndex: 6 }
  },
  {
    path: '/order-url',
    name: 'order-url',
    component: () => import(/* webpackChunkName:"order-url" */'@/views/purchased-cucc/order-url'),
    meta: { title: '视频详情', name: '视频彩铃详情页', pageIndex: 6 }
  },
  {
    path: '/investmentflow-telecom',
    name: 'investmentflow-telecom',
    component: () => import(/* webpackChunkName:"investmentflow-telecom" */'@/views/investmentflow/telecom'),
    meta: { title: 'AI视频彩铃', name: '视频彩铃', pageIndex: 6 }
  },
  {
    path: '/investmentflow-cucc',
    name: 'investmentflow-cucc',
    component: () => import(/* webpackChunkName:"investmentflow-cucc" */'@/views/investmentflow/cucc'),
    meta: { title: 'AI视频彩铃', name: '视频彩铃', pageIndex: 6 }
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 })
})
const pathArr = ['details', 'preview', 'myringstone', 'favorite', 'introduce-video', 'order-page', 'test-preview']

// 解决有开启状态栏头部 不需要滚动条的情况
const pathArr1 = ['login-choice', 'verification-code', 'input-phone', 'detail-login', 'confirm-phone']

const pathArr2 = ['search-page', 'ring-zone']// 不设置minHeight

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  // 公共头
  // hideheader或没有传默认不显示头部
  if ((to.query.header_type !== HeaderType.HideHeader && !!to.query.header_type) || to.name === 'rankList') {
    if (pathArr.includes(to.name)) {
      window.document.body.style.paddingTop = 0
      window.document.body.style.minHeight = ''
      window.document.body.style.height = '100vh'
    } else {
      if (to.meta.home && to.query.header_type === HeaderType.TabHeader && to.name !== 'rankList') {
        window.document.body.style.paddingTop = 0 + 'px'
        if (pathArr2.includes(to.name)) {
          window.document.body.style.height = '100vh'
          window.document.body.style.minHeight = 'auto'
        } else {
          window.document.body.style.minHeight = '100vh'
          window.document.body.style.height = 'auto'
        }
      } else {
        let h = to.query.status_height ? Number(to.query.status_height) + 50 : (32 + 50)
        if (to.query.header_type === HeaderType.TabHeader) {
          h = 50
        }

        window.document.body.style.paddingTop = h + 'px'

        if (pathArr2.includes(to.name)) {
          window.document.body.style.height = 'calc(100vh - ' + h + 'px)'
          window.document.body.style.minHeight = 'auto'
        } else {
          window.document.body.style.minHeight = 'calc(100vh - ' + h + 'px)'
          window.document.body.style.height = '100%'
        }
      }
    }
  }

  // 解决有开启状态栏头部 不需要滚动条的情况
  if (pathArr1.includes(to.name)) {
    window.document.body.style.overflow = 'hidden'
  } else {
    window.document.body.style.overflow = ''
  }

  Vue.prototype.$height = to.query.status_height !== undefined ? Number(to.query.status_height) : 32
  next()
})

export default router
