// import { mapGetters } from 'vuex'
import { getClientDarkMode, getUserInfo } from '@/utils/bridge.js'

export default {
  data () {
    return {
      devappversion: ''
    }
  },
  computed: {
    darkbg () {
      return this.$store.state.app.darkbg
    },
    darkColor () {
      return this.$store.state.app.darkColor
    }
    // ...mapGetters(['darkbg', 'darkColor'])
  },
  watch: {
    darkbg (val) {
      if (val === 1) {
        if (this.darkColor === '#000000') {
          window.document.documentElement.setAttribute('data-theme', 'dark')
        } else if (this.darkColor === '#171717') {
          window.document.documentElement.setAttribute('data-theme', 'dark1')
        } else if (this.darkColor === '#262626') {
          window.document.documentElement.setAttribute('data-theme', 'dark2')
        }
      } else {
        window.document.documentElement.setAttribute('data-theme', 'light')
      }
      window.document.body.style.backgroundColor = this.darkColor
    },

    darkColor (val) {
      if (val === '#000000') {
        window.document.documentElement.setAttribute('data-theme', 'dark')
      } else if (val === '#171717') {
        window.document.documentElement.setAttribute('data-theme', 'dark1')
      } else if (val === '#262626') {
        window.document.documentElement.setAttribute('data-theme', 'dark2')
      }
      window.document.body.style.backgroundColor = val
    }
  },
  created () {
    getUserInfo().then((data) => {
      const data1 = JSON.parse(data)
      this.devappversion = data1['dev-app-version']
      if (this.devappversion === '9000010') {
        setInterval(() => {
          getClientDarkMode().then((darkMode) => {
            this.$store.commit('app/SET_DARKBG', darkMode)
            if (darkMode === 1) {
              localStorage.setItem('bgColor', '000000')
              window.document.documentElement.setAttribute('data-theme', 'dark')
              window.document.body.style.backgroundColor = '#000'
            } else {
              localStorage.setItem('bgColor', 'FAFAFA')
              window.document.documentElement.setAttribute('data-theme', 'light')
              window.document.body.style.backgroundColor = '#fafafa'
            }
          })
        }, 1000)
      } else {
        getClientDarkMode().then((darkMode) => {
          localStorage.setItem('bgColor', JSON.parse(darkMode).bgColor)
          this.$store.commit('app/SET_DARKBG', JSON.parse(darkMode).dark)
          this.$store.commit('app/SET_DARKCOLOR', '#' + JSON.parse(darkMode).bgColor)
        })
      }
    })
  }
}
