import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import appStore from './modules/appStore'
import user from './modules/user'
import ringtone from './modules/ringtone'
import introduce from './modules/introduce'
import investmentFlow from './modules/investmentFlow'
import getters from './getters'
Vue.use(Vuex)
// const modulesFiles = require.context('./modules', true, /\.js$/)
// const module = modulesFiles.keys().reduce((modul, modulePath) => {
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const value = modulesFiles(modulePath)
//   modul[moduleName] = value.default
//   return modul
// }, {})
export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters,
  modules: {
    introduce,
    investmentFlow,
    app,
    appStore,
    ringtone,
    user
  }
})
