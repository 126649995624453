import { getObj } from '@/utils/index.js'
import sensors from '@/sensorsdata'
import store from '@/store'
export function track (params, stop = false) {
  const obj1 = getObj(store.getters.selfInfo)
  if (!obj1.dev_pid) {
    setTimeout(() => {
      track(params, true)
    }, 1000)
    if (stop) {
      return
    }
  }
  if (typeof params[0].event_id !== 'undefined') {
    sensors.track(params[0].event_id, params[0].event_param)
  } else {
    sensors.track(params.event_id, params.event_param)
  }
}
