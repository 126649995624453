<template>
  <div id="app"
       v-cloak>
    <back v-if="!!$route.query.header_type && ($route.query.header_type !== HeaderType.HideHeader ) &&(($route.meta.common && !$route.meta.home) || ($route.meta.common && $route.meta.home && $route.query.header_type === HeaderType.Header ) ) || $route.name === 'rankList'"
          :darkbg1="darkbg" />
   <transition :name="transitionName" >
    <keep-alive include="ring-zone,ringtone-topic,search-page,recommond-list">
      <router-view ></router-view>
    </keep-alive>
  </transition>
  </div>
</template>
<script>
import dark from '@/mixins/dark'
// import { h5DarkChange, KeyboardUp, getUserInfo } from '@/utils/bridge.js'
// import { track } from '@/directives/track/send.js'
import HeaderType from '@/model/HeaderType'

export default {
  name: 'App',
  mixins: [dark],
  components: {
    back: () => import('@/components/header.vue')
  },
  props: {},
  data () {
    return {
      time: Date.now(),
      loadTime: null,
      transitionName: '',
      HeaderType: Object.freeze(HeaderType)
    }
  },
  computed: {

    carrierType () {
      return this.$store.state.ringtone.carrierType
    }
  },
  watch: {
    $route: {
      handler: function (route, oldRoute) {
        if (oldRoute) {
          window.$referrer = location.origin + '/#' + oldRoute.fullPath
        }
        if (route.name) {
          if (this.loadTime) {
            this.loadTime = Date.now()
          } else {
            // this.loadTime = window.loadTime
            this.loadTime = Date.now()
          }
        }
        this.time = Date.now()

        // 处理页面条状路由动画效果
        let to, from
        if (route) to = route.meta.pageIndex
        if (oldRoute) from = oldRoute.meta.pageIndex
        if (!oldRoute) {
          this.transitionName = ''
        } else {
          if (route.name === oldRoute.name || !oldRoute.name) {
            this.transitionName = ''
          } else {
            if (to < from) {
              // 设置动画名称 出来是从左往右
              this.transitionName = 'slide-right'
            } else {
              // 进去是从右往左
              this.transitionName = 'slide-left'
            }
          }
        }

        if (route.name) {
          if (this.loadTime) {
            this.loadTime = Date.now()
          } else {
            this.loadTime = window.loadTime
          }
          this.$nextTick(() => {
            // track([
            //   {
            //     event_id: '$WebPageLoad',
            //     event_param: {
            //       $event_duration: (Date.now() - this.loadTime) / 1000,
            //       content_type: '视频彩铃',
            //       content_id: '',
            //       content_name: route.meta.name
            //     }
            //   }
            // ])
          })
        }
      },
      immediate: true
    }
    // carrierType: {
    //   handler () {
    //     this.$store.dispatch('ringtone/getHotsText')
    //     this.$store.dispatch('ringtone/getHintsText')
    //   },
    //   deep: true
    // }
  },
  created () {
    this.$store.dispatch('app/getConfigPage')
    if (window.KuYinExt && window.KuYinExt.isLogin()) {
      this.$store.commit('app/SET_HUAN_TAI_ISLOGIN', true)
    }
  },

  mounted () {
    // KeyboardUp()
    // const that = this
    // h5DarkChange((data, responseCallback) => {
    //   localStorage.setItem('bgColor', JSON.parse(data).bgColor)
    //   that.$store.commit('app/SET_DARKBG', JSON.parse(data).dark)
    //   that.$store.commit('app/SET_DARKCOLOR', '#' + JSON.parse(data).bgColor)
    //   const responseData = data
    //   responseCallback(responseData)
    // })

    // getUserInfo().then((responseData) => {
    //   console.log(JSON.parse(responseData), 'app')
    //   this.$store.commit('app/SET_SELFINFO', JSON.parse(responseData))
    // })
    // const sensors = window.sensorsDataAnalytic201505
    // const presetProperties = sensors.getPresetProperties()
    // const cookieId = presetProperties.identities.$identity_cookie_id
    // console.log(cookieId, 'app')
    // this.$store.commit('app/SET_COOKIEID', cookieId)
  },
  methods: {
  }
}
</script>

<style lang="scss">
// @import "@/assets/css/_handle.scss";
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  // font-family: "opSansRe";
  width: 100%;
  height: 100%;
  // overflow-x: hidden;
  position: relative;
  //  @include background_color("background_color");
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}
.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

</style>
