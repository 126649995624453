
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/assets/css/custom.scss'
import '@/styles/index.less'
// // 加载第三方字体文件
// import '@/styles/fonts/font.less'
import { Button, Field, Toast, Loading, Icon, Checkbox } from 'vant'
// 自动设置REM基准值
import 'amfe-flexible'
import api from './api'

import track from '@/directives/track'
// import VueLazyLoad from 'vue-lazyload'
// import VueMasonry from 'vue-masonry-css'
// import ProcessDialog from '@/components/processDialog'

Vue.use(track)
// Vue.use(ProcessDialog)
require('es6-promise').polyfill()
Es6Promise.polyfill()

// const loadimage = require('@/assets/image/gif/loading.gif')
// Vue.use(VueLazyLoad, {
//   preLoad: '10px',
//   observer: true
//   // loading: loadimage
// })
Vue.prototype.$http = api
Vue.prototype.$EventBus = new Vue()
Vue.use(Button).use(Icon).use(Field).use(Toast).use(Loading).use(Checkbox)
window.document.documentElement.setAttribute('data-theme', 'light')
Vue.config.ignoredElements = [/^qa-router-button|templates|styles/]
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
