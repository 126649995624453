// 获取本地上传的图片文件的url txt文件路径也可以得到
import Router from '@/router'
import crypto from '@/utils/crypto'
export function getObjectURL (file) {
  let url = null
  if (window.createObjectURL !== undefined) {
    url = window.createObjectURL(file)
  } else if (window.URL !== undefined) {
    url = window.URL.createObjectURL(file)
  } else if (window.webkitURL !== undefined) {
    url = window.webkitURL.createObjectURL(file)
  }
  return url
}
// nonce32位随机字符串
export function randomString () {
  /** **默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  const maxPos = $chars.length
  let randomStr = ''
  for (let i = 0; i < 32; i++) {
    randomStr += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return randomStr
}
export function getOS () {
  let os
  if (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Linux') > -1) {
    os = 'Android'
  } else if (navigator.userAgent.indexOf('iPhone') > -1) {
    os = 'iOS'
  } else if (navigator.userAgent.indexOf('Windows Phone') > -1) {
    os = 'WP'
  } else {
    os = 'Others'
  }
  return os
}
export function getOSVersion () {
  const OSVision = ''
  return OSVision
}
export function getObj (obj) {
  const obj1 = {}
  for (const key in obj) {
    // eslint-disable-next-line no-useless-escape
    const n = key.replace(/\-/g, '_')
    obj1[n] = obj[key]
  }
  return obj1
}
export function getNetType () {
  const type = /NetType/.test(navigator.userAgent) ? navigator.userAgent.match(/NetType\/(\S*)/)[1] : ''
  const bool = type === 'WIFI'
  return bool
}
export function getPhoneType () {
  let model = ''
  const u = navigator.userAgent
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 // Android
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  if (isAndroid) {
    model = u.split('Build/')[0].substring(u.split('Build/')[0].lastIndexOf(';') + 1)
  }
  if (isIOS) {
    if (u.indexOf('iPhone') > -1) {
      model = 'iPhone'
    }
  }
  return model
}
// 时间戳转为指定格式时间 Y-M-D h:m:s 、Y年M月D日 h:m:s等
export function formatTimeDate2 (format = 'YYYY-MM-DD hh:mm:ss', timestamp = '') {
  if (
    (timestamp + '').trim() === '' ||
    (timestamp + '').trim() === 'undefined' ||
    (timestamp + '').trim() === 'NaN' ||
    (timestamp + '').trim() === 'null' ||
    (timestamp + '').trim() === 'false' ||
    (timestamp + '').trim() === '0'
  ) {
    return ''
  }

  const date = new Date(timestamp)
  const dateInfo = {
    Y: date.getFullYear(),
    M: date.getMonth() + 1,
    D: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds()
  }
  const formatNumber = (n) => (n >= 10 ? n : '0' + n)
  const res = format
    .replace('YYYY', dateInfo.Y)
    .replace('MM', formatNumber(dateInfo.M))
    .replace('DD', formatNumber(dateInfo.D))
    .replace('hh', formatNumber(dateInfo.h))
    .replace('mm', formatNumber(dateInfo.m))
    .replace('ss', formatNumber(dateInfo.s))
  return res
}

// 解析url参数
export function parseUrlParams (url) {
  // 将URL解析成对象
  const params = {}
  const queryString = url.split('?')[1]
  if (queryString) {
    const pairs = queryString.split('&')
    for (const pair of pairs) {
      let [key, value] = pair.split('=')
      // 处理编码的值
      if (value) {
        value = decodeURIComponent(value.replace(/\+/g, ' '))
        // 如果已经有了这个键，将其转换为数组
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (!Array.isArray(params[key])) {
            params[key] = [params[key]]
          }
          params[key].push(value)
        } else {
          params[key] = value
        }
      } else {
        params[key] = ''
      }
    }
  }
  return params
}

// 转换成url参数
export function serializeUrlParams (params, unDecode) {
  let queryString = ''
  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      const value = params[key]
      if (value) {
        // 如果值是数组，则将每个值都编码并拼接起来
        if (unDecode) {
          if (Array.isArray(value)) {
            for (const item of value) {
              queryString += `${key}=${item}&`
            }
          } else {
            queryString += `${key}=${value}&`
          }
        } else {
          if (Array.isArray(value)) {
            for (const item of value) {
              queryString += `${encodeURIComponent(key)}=${encodeURIComponent(item)}&`
            }
          } else {
            queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`
          }
        }
      }
    }
  }
  // 删除最后一个&
  queryString = queryString.slice(0, -1)
  return queryString
}

// 节流
export function throttle (func, wait, mustRun) {
  let timeout
  let startTime = new Date()

  return function () {
    const context = this
    const args = arguments
    const curTime = new Date()

    clearTimeout(timeout)
    // 如果达到了规定的触发时间间隔，触发 handler
    if (curTime - startTime >= mustRun) {
      func.apply(context, args)
      startTime = curTime
      // 没达到触发间隔，重新设定定时器
    } else {
      timeout = setTimeout(func, wait)
    }
  }
}

// 防抖
export function debounce (func, wait, immediate) {
  // timeout 是定时器对象， args 对应返回函数的 实参列表 argument, result 是被限制防抖函数中的返回值
  let timeout, args, result
  return function () {
    // 获取 this 指向存起来，下边定时器中的 func 函数被 apply 调用时用来改变 this 执行，或者定时器函数中直接使用箭头函数
    const context = this
    // 接收实参列表，如果涉及到 dom 操作，可能会用到 event 对象，这里用来接收
    args = arguments
    // 函数被触发，先取消定时器，一直触发一直清空，只有等停止触发以后，最后一次清空以后，才会执行下边的代码
    clearTimeout(timeout)
    // 立即执行，然后在规定时间内不再触发
    if (immediate) {
      // 第一次触发，timeout = undefined, 取反即为 true, 下边会立即执行，再次触发， timeout已经有值，取反即为 false,下边便不会在立即执行
      const callNow = !timeout
      // 规定时间到了以后，timeout 置空，再次触发，上边 timeout 取反 即为 true, 下边再次执行
      timeout = setTimeout(() => {
        timeout = null
      }, wait)
      // callNow === true 立即执行
      if (callNow) {
        result = func.apply(context, args)
      }
    } else {
      // 延迟执行，直接延迟规定时间再执行
      timeout = setTimeout(function () {
        result = func.apply(context, args)
      }, wait)
    }
    // 考虑到如果被限制防抖函数中有返回值的情况，这里直接 return 返回值
    return result
  }
}

/**
 * 判断元素是否在窗口内
 * @param {HTMLElement} el - 需要判断的元素
 * @returns {boolean} 元素是否在窗口内
 */
export function isElementInViewport (el) {
  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}
export function deeplinkto (route, query, showTitle) {
  if (!showTitle) {
    const querystr = serializeUrlParams(query, true)
    const url = window.location.origin + '/' + Router.resolve(route).href
    const dplink =
    'allmusic://internalWebPage/url/' + `${url}?${querystr}&show_title=1`
    console.log(dplink)
    window.location.href = dplink
    //     window.open(`${url}?${querystr}&show_title=1`, '_blank')
  } else {
    query.header_type = 2
    const querystr = serializeUrlParams(query, true)
    const url = window.location.origin + '/' + Router.resolve(route).href
    const dplink =
    'allmusic://internalWebPage/url/' + `${url}?${querystr}&show_title=${showTitle}`
    console.log(dplink)
    //    window.open(`${url}?${querystr}&show_title=1`, '_blank')
    window.location.href = dplink
  }
}

export function getUidByUrl () {
  const url = decodeURIComponent(window.location.href)
  const queryString = url.split('?')[1] || ''
  const query = {}
  // eslint-disable-next-line array-callback-return
  queryString.split('&').map((item) => {
    const list = item.split('=')
    query[list[0]] = list[1]
  })
  // eslint-disable-next-line prefer-regex-literals
  const reg = new RegExp('amp;', 'g')
  const $query = JSON.parse(JSON.stringify(query).replace(reg, ''))
  const puidObj = {}

  if ($query.puid) {
    const puid = crypto.byteBase64($query.puid)
    const pudilist = puid.split(',')

    pudilist.forEach((element) => {
      const list = element.split(':') || []
      if (list.length === 2) {
        puidObj[list[0]] = list[1]
      }
    })
  }

  return puidObj
}

// 异步插入脚本并在执行完毕后回调
export function loadMiguSdKScript (src, id) {
  return new Promise(function (resolve, reject) {
    const dom = document.getElementById(id)
    if (dom) {
      resolve(true)
    } else {
      const script = document.createElement('script')
      script.id = id
      script.src = src
      script.onload = resolve
      script.onerror = reject
      document.body.appendChild(script)
    }
  })
}
