// 套餐状态
const PackageStatusType = {
  // 未退订  仍然持有
  Subscribed: 0,

  // 退订 不在持有
  Unsubscribed: 2
}

export default PackageStatusType
