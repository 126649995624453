import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'
Toast.setDefaultOptions({ duration: 1500 })
const instance = axios.create({
  timeout: 1000 * 12,
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_SRC2 : process.env.VUE_APP_SRC2
})

// 存放接口取消的调用方法
window._axiosCancelArr = []
instance.interceptors.request.use(
  function (config) {
    const storageStr = window.localStorage.getItem('userInfo')
    const vuexState = store.state.app.userInfo ? store.state.app.userInfo.TOKEN : ''
    // 缓存有取缓存token，没有取store的token
    const token = storageStr ? JSON.parse(storageStr).TOKEN : vuexState
    config.headers.token = token || ''
    if (process.env.VUE_APP_GRAY === '1') {
      // eslint-disable-next-line dot-notation, quotes
      config.headers["gray"] = process.env.VUE_APP_GRAY
    }

    // 取消请求

    config.cancelToken = new axios.CancelToken((cancel) => {
      window._axiosCancelArr.push({ cancel })
    })

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    // 取消接口不提示
    if (error.code === 'ERR_CANCELED') {
      return Promise.reject(error)
    }
    if (error.response.status) {
      switch (error.response.status) {
        case 404:
          Toast({
            message: '网络请求不存在',
            duration: 1500,
            forbidClick: true
          })
          break
        default:
          Toast({
            message: error.response.data.message,
            duration: 1500,
            forbidClick: true
          })
      }
      return Promise.reject(error.response)
    }
  }
)
export default instance
