// import Exposure from './exposure'
// import Click from './click'
// import Input from './input'
// import Touchstart from './touchstart'

// const exp = new Exposure()
// const cli = new Click()
// const inp = new Input()
// const tou = new Touchstart()

const vueTrack = (Vue) => {
  Vue.directive('track', {
    bind (el, binding) {
      // trackSplit(binding, el)
    },
    update (el, binding) {
      // trackSplit1(binding, el)
    }
  })
}
// function trackSplit1 (binding, el) {
//   const { arg, value } = binding
//   arg.split('|').forEach((item) => {
//     el.trackParams = value
//     if (item === 'click') {
//       cli.update({ el }, binding)
//     }
//   })
// }

// function trackSplit (binding, el) {
//   const { arg, value } = binding
//   arg.split('|').forEach((item) => {
//     el.trackParams = value
//     if (item === 'click') {
//       cli.add({ el }, binding)
//     } else if (item === 'exposure') {
//       exp.add({ el })
//     } else if (item === 'input') {
//       inp.add({ el })
//     } else if (item === 'touchstart') {
//       tou.add({ el })
//     }
//   })
// }

export default vueTrack
