import { Toast, Dialog } from 'vant'
import Router from '@/router'

import sourceApi from '@/api/modules/source'
import CarrierType from '@/model/CarrierType'
import PackageStatusType from '@/model/PackageStatusType'
import Pcid from '@/model/PcidType'
import { exitPage } from '@/utils/bridge.js'
import { track } from '@/directives/track/send.js'
import { deeplinkto, parseUrlParams, serializeUrlParams } from '@/utils/index'
const sensors = window.sensorsDataAnalytic201505
export const actions = {
  // 电信订阅
  async orderTelecom ({ commit, state, dispatch, rootState, getters: { processConfig } }, payload) {
    debugger
    let isCurrentPageSetting = false
    let openOrder = false
    let openNew = false
    let isNoComfirn = false
    if (payload) {
      isCurrentPageSetting = payload.isCurrentPageSetting
      openOrder = !!payload.openOrder
      openNew = !!payload.openNew
      isNoComfirn = !!payload.isNoComfirn
    }
    Toast.clear()
    Toast.loading({ forbidClick: true, duration: 1500 })
    const mobile = state.phone
    const curerntPageParams = parseUrlParams(location.href)
    curerntPageParams.videoId = state.settingData.videoId
    curerntPageParams.copyRightID = state.settingData.copyRightID
    curerntPageParams.productId = state.settingData.productId
    const returnUrl = window.location.href.split('?')[0] + '?' + serializeUrlParams(curerntPageParams)

    const isRingIdEmpty = (!state.settingData.videoId) || !curerntPageParams.packageId // 彩铃id是否为空
    const isCorrectId = state.settingData.videoId && (state.settingData.videoId.length !== 12) // 彩铃id是否是移动id
    if (((isRingIdEmpty || isCorrectId)) && rootState.app.configData.backup_ringid_telecom) {
      const backupid = rootState.app.configData.backup_ringid_telecom
      const backupproductid = '135999999999999000351'
      commit('SET_SETTING_VIDEOID', backupid)
      commit('SET_SETTING_PRODUCT_ID', backupproductid)
      curerntPageParams.videoId = backupid
      curerntPageParams.productId = backupproductid

      const clickTrackParam2 = {
        content_id: state.settingData.videoId,
        content_name: state.settingData.desc,
        $element_content: '设为彩铃（内容）',
        $element_id: 'next-step',
        $element_type: 'div'
      }
      clickTrackParam2.content_id = backupid + '(空)'
      clickTrackParam2.content_name = '(空)'
      track([{ event_id: '$WebClick', event_param: clickTrackParam2 }])
    }

    await dispatch('user/getUserToken', { phone: state.phone }, { root: true })
    if (Router.currentRoute.query.tabType === 'tab-home') {
      // window.close()
      const query = {
        phone: state.phone,
        token: rootState.app.userInfo.TOKEN,
        carrierType: state.carrierType,
        sourceChange: true,
        sourceChangeConfirm: true,
        loginStatus: rootState.app.loginStatus,
        date: new Date().getTime()
      }
      window.localStorage.setItem('tag-home-fresh', JSON.stringify(query))
      console.log('prepare', JSON.stringify(query))
    }
    dispatch('checkMobileTrack', { type: '26', res: '', isSuccess: false, carrierName: CarrierType.TELECOM })
    dispatch('handleActionApi', {
      isp: CarrierType.ENUM[CarrierType.TELECOM],
      token: rootState.app.userInfo.TOKEN,
      phone: rootState.app.userInfo.phone,
      action: '26',
      interfaceName: '/api/telecom/package/list',
      resCode: '',
      resMessage: ''
    })
    dispatch('getPackageList', { mobile: state.phone, pcid: Router.currentRoute.query.pcid }).then((res) => {
      dispatch('checkMobileTrack', {
        type: '27',
        res,
        isSuccess: res.code === '0000',
        carrierName: CarrierType.TELECOM
      })
      dispatch('handleActionApi', {
        isp: CarrierType.ENUM[CarrierType.TELECOM],
        token: rootState.app.userInfo.TOKEN,
        phone: rootState.app.userInfo.phone,
        action: '27',
        interfaceName: '/api/telecom/package/list',
        resCode: res.code,
        resMessage: JSON.stringify(res)
      })
      const presetProperties = sensors.getPresetProperties()
      const cookieId = presetProperties.identities.$identity_cookie_id

      let status = null
      const packageId = state.settingData.productId
      res.data?.forEach((element) => {
        if (element.package_id === packageId) {
          status = element.status
        }
      })
      const getItemaddtoneFreeParams = JSON.parse(window.sessionStorage.getItem('addtoneFreeParams')) || ''
      if (status === PackageStatusType.Subscribed) {
        // 1.一键登录的手机号是当前使用的 手机号则不需要验证码
        // 2.没确认登陆但是已经书输入过验证码
        // 3.已经确认登录 则不需要验证码
        console.log(rootState.app)
        console.log(rootState.app.loginStatus)
        console.log(rootState.app.codeValidate)
        if (
          (state.oneClickPhone && state.oneClickPhone === state.phone) ||
            (rootState.app.codeValidate &&
              !rootState.app.loginStatus &&
              JSON.stringify(rootState.app.userInfo) !== '{}') ||
            rootState.app.loginStatus
        ) {
          // 未确认登录需要 输入验证码后确认
          let phoneNumber = state.phone
          let toneCode = state.settingData.videoId
          if (getItemaddtoneFreeParams && Router.currentRoute.query.order_no) {
            phoneNumber = getItemaddtoneFreeParams.phoneNumber
            toneCode = getItemaddtoneFreeParams.toneCode
            window.sessionStorage.removeItem('addtoneFreeParams')
            window.localStorage.removeItem('productId')
          }
          sourceApi
            .addtoneFree({
              phoneNumber,
              toneCode,
              promotionChannel: Router.currentRoute.query.pcid,
              pid: rootState.app.selfInfo['dev-pid'],
              uid: rootState.app.selfInfo.uid,
              cookieId: cookieId
            })
            .then((res) => {
              dispatch('checkMobileTrack', {
                type: '30',
                res,
                isSuccess: res.code === '0000',
                carrierName: CarrierType.TELECOM
              })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.TELECOM],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '30',
                interfaceName: '/telecom/addtone/free',
                resCode: res.code,
                resMessage: JSON.stringify(res)
              })
              Toast.clear()
              if (res.code === '0000') {
                window.localStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))
                commit('SET_PHONE', phoneNumber)
                commit('app/SET_LOGIN_STATUS', true, { root: true })
                dispatch('loginConfirm')
                Toast.success({
                  message: '设置成功',
                  forbidClick: true,
                  duration: 1500,
                  onClose: () => {
                    if (Router.currentRoute.query.tabType === 'tab-home') {
                      exitPage()
                    }
                    if (!isCurrentPageSetting) {
                      const pageCount = state.historyPage.length ? state.historyPage.length : 1
                      if (Router.currentRoute.query.order_no) {
                        Router.go(-(pageCount + 3)) // 电信订购成功之后直接跳回我们页面。此时订购成功回退的时候需要多回跳2个页面
                      } else {
                        Router.go(-pageCount)
                      }
                    }
                  }
                })
              } else {
                if (res.message === '用户不存在') {
                  Dialog({
                    title: '温馨提示',
                    message:
                        '抱歉，您的视频彩铃功能未开通。请先在应用商店下载【爱音乐】app，然后在【我的】-【会员中心】开通功能。',
                    confirmButtonText: '我知道了',
                    className: 'telecom-msg sendMsg-dialog '
                  })
                  setTimeout(() => {
                    Dialog.close()
                  }, 1000)
                } else {
                  Toast.fail({
                    message: res.message || '设置失败',
                    forbidClick: true,
                    duration: 1500
                  })
                }
              }
              window.localStorage.removeItem('productId')
            })
            .catch((err) => {
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.TELECOM],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '31',
                interfaceName: '/telecom/addtone/free',
                resCode: 'err',
                resMessage: JSON.stringify(err)
              })
            })
        } else {
          const query = {
            ...Router.currentRoute.query,
            ...state.settingData
          }
          if (Number(Router.currentRoute.query.status_height) > 0) {
            query.status_height = Router.currentRoute.query.status_height
          }
          if (openNew) {
            delete query.status_height
            delete query.header_type
            query.tabType = 'tab-home'
            deeplinkto('/verification-code', query)
          } else {
            Router.push({
              name: 'verification-code',
              query,
              params: {
                isPush: true
              }
            })
          }
        }
      } else {
        if (
          (isCurrentPageSetting &&
              !state.sourceChange &&
              !!(window.KuYinExt && window.KuYinExt.requestAuthorizationAs) &&
              !openOrder && !(Pcid.Apps.includes(Router.currentRoute.query.pcid)) && (rootState.appStore.showDetailStyle !== 'b')) ||
            (!isNoComfirn && (Router.currentRoute.name !== 'confirm-phone' && rootState.app.processConfig[1].page) && !(rootState.app.configData.no_choice_page === '1') && !isNoComfirn && !(Pcid.Apps.includes(Router.currentRoute.query.pcid)) && (rootState.appStore.showDetailStyle !== 'b')) ||
            ((Router.currentRoute.name !== 'inputphone-oneclick' && rootState.app.processConfig[2].page) && !!(rootState.app.configData.no_choice_page === '1') && !isNoComfirn && !(Pcid.Apps.includes(Router.currentRoute.query.pcid)) && (rootState.appStore.showDetailStyle !== 'b')) // 播放渠道搜索渠道 并且播放样式不是b
        ) {
          const query = {
            ...Router.currentRoute.query,
            ...state.settingData
          }
          if (Number(Router.currentRoute.query.status_height) > 0) {
            query.status_height = Router.currentRoute.query.status_height
          }
          if (Router.currentRoute.name === 'input-phone') {
            if (openNew) {
              delete query.header_type
              delete query.status_height
              query.tabType = 'tab-home'
              if (rootState.app.configData.tab_home_setting_page === '1') {
                deeplinkto('/details', query, '0')
                return
              }

              if (rootState.app.configData.no_choice_page === '1') {
                deeplinkto('/inputphone-oneclick', query)
                return
              }
              deeplinkto('/confirm-phone', query)
            } else {
              if (rootState.app.configData.no_choice_page === '1') {
                Router.push({
                  name: 'inputphone-oneclick',
                  query,
                  params: {
                    isPush: true
                  }
                })
                return
              }
              Router.replace({
                name: 'confirm-phone',
                query,
                params: {
                  isPush: true
                }
              })
            }
          } else {
            if (openNew) {
              delete query.header_type
              delete query.status_height
              query.tabType = 'tab-home'
              if (rootState.app.configData.tab_home_setting_page === '1') {
                deeplinkto('/details', query, '0')
                return
              }
              if (rootState.app.configData.no_choice_page === '1') {
                deeplinkto('/inputphone-oneclick', query)
                return
              }
              deeplinkto('/confirm-phone', query)
            } else {
              if (rootState.app.configData.no_choice_page === '1') {
                Router.push({
                  name: 'inputphone-oneclick',
                  query,
                  params: {
                    isPush: true
                  }
                })
                return
              }
              Router.push({
                name: 'confirm-phone',
                query,
                params: {
                  isPush: true
                }
              })
            }
          }
        } else {
          // 没有定
          // H5 计费下单发起接口（一键开户）
          dispatch('handleActionApi', {
            isp: CarrierType.ENUM[CarrierType.TELECOM],
            token: rootState.app.userInfo.TOKEN,
            phone: rootState.app.userInfo.phone,
            action: '28',
            interfaceName: '/api/telecom/openOrder',
            resCode: '',
            resMessage: ''
          })
          dispatch('handleActionApi', {
            isp: CarrierType.ENUM[CarrierType.TELECOM],
            token: rootState.app.userInfo.TOKEN,
            phone: rootState.app.userInfo.phone,
            action: '28',
            interfaceName: '设置id' + state.settingData.videoId,
            resCode: '',
            resMessage: ''
          })

          dispatch('checkMobileTrack', { type: '28', res: '', isSuccess: false, carrierName: CarrierType.TELECOM })
          sourceApi
            .openOrder({
              mobile,
              returnUrl,
              productId: state.settingData.productId,
              verifyType: 1,
              isMessage: 1,
              ringId: state.settingData.videoId,
              promotionChannel: Router.currentRoute.query.pcid,
              pid: rootState.app.selfInfo['dev-pid'],
              uid: rootState.app.selfInfo.uid,
              cookieId: cookieId
            })
            .then((resO) => {
              dispatch('checkMobileTrack', {
                type: '29',
                res: resO,
                isSuccess: resO.code === '0000',
                carrierName: CarrierType.TELECOM
              })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.TELECOM],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '29',
                interfaceName: '/api/telecom/openOrder',
                resCode: resO.code,
                resMessage: JSON.stringify(resO)
              })
              Toast.clear()
              if (resO.code === '0000') {
                sourceApi.channelAdd({
                  mobile: state.phone,
                  type: ~~CarrierType.TELECOM,
                  promotionChannel: Router.currentRoute.query.pcid, // 推广渠道
                  pid: rootState.app.selfInfo['dev-pid'],
                  uid: rootState.app.selfInfo.uid,
                  cookieId: cookieId
                })
                if (resO.data) {
                  track([{ event_id: 'h5_color_ring_link_order_page_success', event_param: { content_name: CarrierType.ENUM[CarrierType.TELECOM], carrier_name: CarrierType.ENUM[CarrierType.TELECOM] } }])
                  window.sessionStorage.setItem(
                    'addtoneFreeParams',
                    JSON.stringify({
                      phoneNumber: mobile,
                      toneCode: state.settingData.videoId,
                      contentId: state.settingData.videoId,
                      contentName: state.settingData.desc
                    })
                  )

                  window.localStorage.setItem('productId', state.settingData.productId)
                  // 先存到session中方便会挑时恢复状态
                  window.sessionStorage.setItem('processConfig', JSON.stringify(rootState.app.processConfig))
                  // 先存到session中方便会挑时恢复状态
                  window.sessionStorage.setItem('carrierType', state.carrierType)
                  // 跳转之前先把token phone信息 先存到session中方便会挑时恢复状态
                  window.sessionStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))
                  // 储存历史页面记录
                  window.sessionStorage.setItem('historyPage', JSON.stringify(state.historyPage))
                  if (Router.currentRoute.query.searchkey) {
                    window.sessionStorage.setItem('searchList', JSON.stringify(rootState.appStore.searchList))
                    window.sessionStorage.setItem('searchParams', JSON.stringify(rootState.appStore.searchParams))
                  }
                  // 综搜跟播放页使用过来的
                  if (Pcid.Apps.includes(Router.currentRoute.query.pcid)) {
                    window.sessionStorage.setItem(
                      'importTypeDataList',
                      JSON.stringify(rootState.appStore.importTypeDataList)
                    )
                  }
                  // 切换资源的内容
                  if (JSON.stringify(rootState.appStore.changeSourceList) !== '[]') {
                    window.sessionStorage.setItem(
                      'changeSourceList',
                      JSON.stringify(rootState.appStore.changeSourceList)
                    )
                  }

                  // tab二级内容
                  if (JSON.stringify(rootState.appStore.tabHomeListQuery) !== '[]') {
                    window.sessionStorage.setItem(
                      'tabHomeListQuery',
                      JSON.stringify(rootState.appStore.tabHomeListQuery)
                    )
                  }

                  // tab二级内容 tabHomeListQuery
                  if (JSON.stringify(rootState.appStore.tabHomeList) !== '[]') {
                    window.sessionStorage.setItem(
                      'tabHomeList',
                      JSON.stringify(rootState.appStore.tabHomeList)
                    )
                  }

                  // 分类资源
                  if (JSON.stringify(state.categorys) !== '[]') {
                    window.sessionStorage.setItem('categorys', JSON.stringify(state.categorys))
                  }

                  // 刷新随机资源
                  if (JSON.stringify(rootState.appStore.randomList) !== '[]') {
                    window.sessionStorage.setItem('randomList', JSON.stringify(rootState.appStore.randomList))
                  }

                  window.sessionStorage.setItem(
                    'cancelOrder',
                    JSON.stringify({ ...state.settingData, sourceType: state.carrierType, phone: state.phone })
                  )
                  window.location.href = resO.data.feeUrl
                } else {
                  Toast.fail(resO.message)
                }
              } else if (resO.code === '3001') {
                Toast.fail('登录过期，请重新登录')
                // this.loginDialog = true
              } else if (resO.code === '5000') {
                if (getItemaddtoneFreeParams && Router.currentRoute.query.order_no) {
                  window.sessionStorage.removeItem('addtoneFreeParams')
                  window.localStorage.removeItem('productId')
                } else {
                  if (!rootState.app.loginStatus) {
                    commit('app/SET_USERINFO', {}, { root: true })
                    // commit('SET_PHONE', '')
                    // commit('SET_CARRIER_TYPE', CarrierType.MIGU)
                  }
                }
                Toast.fail(resO.message)
              } else {
                Toast.fail(resO.message)
                if (!rootState.app.loginStatus) {
                  commit('app/SET_USERINFO', {}, { root: true })
                  // commit('SET_PHONE', '')
                  // commit('SET_CARRIER_TYPE', CarrierType.MIGU)
                }
              }
            })
            .catch((err) => {
              Toast.clear()
              dispatch('checkMobileTrack', {
                type: '29',
                res: JSON.stringify(err),
                isSuccess: false,
                carrierName: CarrierType.TELECOM
              })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.TELECOM],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '29',
                interfaceName: '/api/telecom/openOrder',
                resCode: 'err',
                resMessage: JSON.stringify(err)
              })
            })
        }
      }
    })
  }

}
