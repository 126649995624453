
import interfaces from '../interface/index'
import request from '@/request/index.js'
import cryptoRequest from '@/request/cryptoRequest'
import request2 from '@/request/request2.js'
import qs from 'qs'
const user = {
  queryBanner (params) {
    return request.get(`${interfaces.queryBanner}`, {
      params: params
    })
  },
  queryCategoryVideos (params) {
    return request.get(`${interfaces.queryCategoryVideos}`, {
      params: params
    })
  },
  queryVideoByCategoryId (params) {
    return request.get(`${interfaces.queryVideoByCategoryId}`, {
      params: params
    })
  },
  queryVideoList (params) {
    return request.get(`${interfaces.queryVideo}`, {
      params: params
    })
  },
  queryQa (params) {
    return request.get(`${interfaces.queryQa}`, {
      params: params
    })
  },
  settingRingOnePointMon (data) {
    return request.post(`${interfaces.settingRingOnePointMon}`, qs.stringify(data))
  },

  getOrderList (params) {
    return request.get(`${interfaces.getOrderList}`, {
      params: params
    })
  },
  qyrSubedProducts (params) {
    return request.get(`${interfaces.qrySubedProducts}`, {
      params: params
    })
  },
  unSubProductWithVCode (data) {
    return request.post(`${interfaces.unSubProductWithVCode}`, qs.stringify(data))
  },
  delOrder (data) {
    return request.post(`${interfaces.delOrder}`, qs.stringify(data))
  },
  sendVerifyCode (params) {
    return request.get(`${interfaces.sendVerifyCode}`, {
      params: params
    })
  },
  openOrder (data) {
    return request.post(`${interfaces.openOrder}`, qs.stringify(data))
  },
  packageList (params) {
    return request.get(`${interfaces.packageList}`, {
      params: params
    })
  },
  addtoneFree (data) {
    return request.post(`${interfaces.addtoneFree}`, qs.stringify(data))
  },
  orderDetail (params) {
    return request.get(`${interfaces.orderDetail}`, {
      params: params
    })
  },
  checkMobile (params) {
    return request.get(`${interfaces.checkMobile}`, {
      params: params
    })
  },
  ringStatus (params) {
    return request.get(`${interfaces.ringStatus}`, {
      params: params
    })
  },
  monthStatusV1 (params) {
    return request.get(`${interfaces.monthStatusV1}`, {
      params: params
    })
  },
  mobileRingOpen (data) {
    return request.post(`${interfaces.mobileRingOpen}`, qs.stringify(data))
  },
  monthStatusV2 (params) {
    return request.get(`${interfaces.monthStatusV2}`, {
      params: params
    })
  },
  toneFreeOrderV1 (data) {
    return request.post(`${interfaces.toneFreeOrderV1}`, qs.stringify(data))
  },
  toneFreeOrderV2 (data) {
    return request.post(`${interfaces.toneFreeOrderV2}`, qs.stringify(data))
  },
  ringCallBack (data) {
    return request.post(`${interfaces.ringCallBack}`, qs.stringify(data))
  },
  queryIntroduce (params) {
    return request.get(`${interfaces.queryIntroduce}`, {
      params: params
    })
  },
  // 搜索底纹
  searchHints (params) {
    return request.get(`${interfaces.searchHints}`, {
      params: params
    })
  },
  // 热搜词
  searchHots (params) {
    return request.get(`${interfaces.searchHots}`, {
      params: params
    })
  },
  // 彩铃搜索
  searchPages (params) {
    return request.get(`${interfaces.searchpages}`, {
      params: params
    })
  },
  // 根据id获取彩铃的视频
  searchDetailById (params) {
    return request.get(`${interfaces.searchDetailById}`, {
      params: params
    })
  },
  // 根据歌曲、歌手推荐获取相关视频
  searchRel (params) {
    return request.get(`${interfaces.searchRel}`, {
      params: params
    })
  },
  // 渠道记录
  channelAdd (data) {
    return request.post(`${interfaces.channeladd}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  // bw订购记录
  actionlogApi (data) {
    return request.post(`${interfaces.actionlog}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  // 查看配置提示语
  queryConfig () {
    return request.get(`${interfaces.queryConfig}`, {
    })
  },
  queryTopic (params) {
    return cryptoRequest.get(`${interfaces.queryTopic}`, {
      params: params
    })
  },
  queryRingRel (params) {
    return cryptoRequest.get(`${interfaces.queryRingRel}`, {
      params: params
    })
  },
  queryRandom (params) {
    return cryptoRequest.get(`${interfaces.queryRandom}`, {
      params: params
    })
  },
  qrySubedProductsNoToken (data) {
    return request2.post(`${interfaces.qrySubedProductsNoToken}`, data)
  },
  settingRingOnePointMon2 (data) {
    return request2.post(`${interfaces.settingRingOnePointMon2}`, data)
  },
  onePointProductMon (data) {
    return request2.post(`${interfaces.onePointProductMon}`, data)
  }
}

export default user
