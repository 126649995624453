
import interfaces from '../interface/index'
import request from '@/request/request.js'
const song = {
  queryRankSongList (params) {
    return request.get(`${interfaces.queryRankSongList}`, {
      params: params
    })
  },
  favoriteSongList (params) {
    return request.get(`${interfaces.favoriteSongList}`, {
      params: params
    })
  },

  deleteFavoriteSong (params, data) {
    return request.post(`${interfaces.deleteFavoriteSong}` + '?uid=' + params.uid + '&utoken=' + params.utoken + '&sign=' + params.sign + '&ts=' + params.ts + '&nonce=' + params.nonce, { ringtongIds: data.ringtongIds })
  }

}

export default song
