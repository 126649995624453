import ProcessDialog from '@/components/dialog/src/index.vue'
import Vue from 'vue'
let ProcessConstructor, instance

function install (Vue) {
  ProcessConstructor = Vue.extend(ProcessDialog)
  instance = new ProcessConstructor().$mount()
  // 实例生成 但是不挂载
  instance.isMounted = false
  Vue.prototype.$myDialog = (options) => {
    if (!instance.isMounted) {
      document.body.appendChild(instance.$el)
      instance.isMounted = true
    }
    instance.init()
  }
}
const CallFunction = function (data) {
  return new Promise((resolve, reject) => {
    if (!instance) {
      ProcessConstructor = Vue.extend(ProcessDialog)
      instance = new ProcessConstructor().$mount()
      document.body.appendChild(instance.$el)
      instance.isMounted = true
    } else {
      if (!instance.isMounted) {
        // 调用的时候 才u噶在
        document.body.appendChild(instance.$el)
        instance.isMounted = true
      }
      instance.init(resolve, reject, data)
    }
  })
}
CallFunction.install = install

CallFunction.close = function () {
  if (instance) {
    instance.close()
  }
}

CallFunction.setPercentage = function (num) {}

export default CallFunction
