import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'

import crypto from '@/utils/crypto'
Toast.setDefaultOptions({ duration: 1500 })
const instance = axios.create({
  timeout: 1000 * 12,
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_SRC2 : process.env.VUE_APP_SRC2
})
instance.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
instance.defaults.headers['Content-Security-Policy'] = 'upgrade-insecure-requests'

const decryptKey = 'XhQLwJNurvEUsTKm'
// 存放接口取消的调用方法
window._axiosCancelArr = []
instance.interceptors.request.use(
  function (config) {
    const storageStr = window.localStorage.getItem('userInfo')
    const vuexState = store.state.app.userInfo ? store.state.app.userInfo.TOKEN : ''
    // 缓存有取缓存token，没有取store的token
    const token = storageStr ? JSON.parse(storageStr).TOKEN : vuexState
    config.headers.token = token || ''
    if (process.env.VUE_APP_GRAY === '1') {
      // eslint-disable-next-line dot-notation, quotes
      config.headers["gray"] = process.env.VUE_APP_GRAY
    }
    if (config.url.includes('user/phone')) {
      config.headers['Content-Type'] = 'application/form-data'
      config.data.forEach((val, key) => {
        console.log(key, val)
        if (key !== 'Referer') {
          config.headers[key] = val
        }
      })
    }

    // 取消请求

    config.cancelToken = new axios.CancelToken((cancel) => {
      window._axiosCancelArr.push({ cancel })
    })

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      // debugger
      const res = response.data
      let resText = ''
      try {
        if (response.data.data) {
          resText = crypto.decrypt(response.data.data, decryptKey)
        } else {
          resText = crypto.decrypt(response.data, decryptKey)
        }
      } catch (err) {
        resText = response.data
      }

      try {
        if (typeof res === 'string') {
          const res = JSON.parse(resText)
          return Promise.resolve(res)
        } else {
          res.data = JSON.parse(resText)
          return Promise.resolve(res)
        }
      } catch (err) {
        res.data = resText
        return Promise.resolve(res)
      }
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    // 取消接口不提示
    if (error.code === 'ERR_CANCELED') {
      return Promise.reject(error)
    }
    if (error.response.status) {
      switch (error.response.status) {
        case 404:
          Toast({
            message: '网络请求不存在',
            duration: 1500,
            forbidClick: true
          })
          break
        default:
          Toast({
            message: error.response.data.message,
            duration: 1500,
            forbidClick: true
          })
      }
      return Promise.reject(error.response)
    }
  }
)
export default instance
