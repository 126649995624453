<template>
  <van-dialog v-model="show" title="智媒彩铃会员服务" confirm-button-color="#EB3B2F" :showConfirmButton="false">
    <van-icon class="close" name="cross" @click="handleCancel" />
    <div class="content">
      <p>尊敬的（{{ phone | phoneFilter }}）用户，您好</p>
      <p style="text-indent: 2em">您正在开通智媒彩铃业务，是否确认开通？开通后可尊享全场视频彩铃无限次随心更换。</p>
      <div v-if="rules" v-html="rules"></div>
      <template v-else>
        <p class="desc" style="color: black">
          温馨提示:<br />
          1.订购成功立即生效，话费支付，当月不退订则次月自动续订，如未退订则按自然月收费.<br />
          2.资费6元/月.<br />
          3.退订方式:可咨询合作方客服热线4001519191咨询退订或登陆中国联通APP-增值业务退订-根据提示操作退订。<br />
          4.由欧圣茨（成都）科技发展有限公司为您提供移动信息服务。
        </p>
      </template>
    </div>

    <div style="text-align: center; margin-bottom: 20px">
      <van-button color="#EB3B2F" @click="handleConfirm" round style="width: 220px">确认开通</van-button>
    </div>
  </van-dialog>
</template>

<script>
import { track } from '@/directives/track/send.js'
export default {
  name: 'process-com',
  data () {
    return {
      timer: null,
      percentage: 0,
      width: 0,
      show: false,
      resolve: null,
      reject: null,
      rules: '',
      phone: ''
    }
  },
  filters: {
    phoneFilter (value) {
      if (!value) return ''
      if (value.length > 0) {
        return value.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
      }
      return value
    }
  },
  computed: {},
  mounted () {},
  methods: {
    handleCancel () {
      this.show = false
      const clickTrackParam = {
        $element_content: '用户取消操作',
        $element_id: 'cancel-btn',
        $element_type: 'div'
      }
      track([{ event_id: '$WebClick', event_param: clickTrackParam }])
      this.reject()
    },
    handleConfirm () {
      this.show = false
      // debugger
      this.resolve(this.phone)
      const clickTrackParam = {
        $element_content: '确认开通',
        $element_id: 'confirm-btn',
        $element_type: 'div'
      }
      track([{ event_id: '$WebClick', event_param: clickTrackParam }])
    },
    init (resolve, reject, data) {
      const clickTrackParam = {
        $element_content: '联通弹窗显示',
        $element_id: 'dialog',
        $element_type: 'div'
      }
      track([{ event_id: '$WebClick', event_param: clickTrackParam }])
      this.show = true
      this.resolve = resolve
      this.reject = reject
      this.phone = data.phone
      this.rules = data.rules
    },
    close () {
      this.show = false
      const clickTrackParam = {
        $element_content: '用户取消操作',
        $element_id: 'cancel-btn',
        $element_type: 'div'
      }
      track([{ event_id: '$WebClick', event_param: clickTrackParam }])
    }
  }
}
</script>

<style lang="scss" scoped>
.close {
  position: absolute;
  right: 20px;
  top: 20px;
}
.content {
  padding: 20px;
  padding-top: 0px;
  font-size: 14px;
  .desc {
    color: #d1d1d1;
    font-size: 12px;
    line-height: 20px;
  }
}
</style>
