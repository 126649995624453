import { Toast } from 'vant'
import Router from '@/router'
import CuccStatusType from '@/model/CuccStatusType'
import sourceApi from '@/api/modules/source'

import CarrierType from '@/model/CarrierType'
import PackageStatusType from '@/model/PackageStatusType'
import Pcid from '@/model/PcidType'
import { parseUrlParams, serializeUrlParams } from '@/utils/index'
import { track } from '@/directives/track/send.js'
const ringTone = {
  namespaced: true,
  state: () => {
    return {
      ringStatusType: null,
      carrierType: localStorage.getItem('userInfo') ? localStorage.getItem('userType') : CarrierType.MIGU,
      currentRingId: '',
      phone: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).phone : '', //
      oneClickPhone: localStorage.getItem('oneClickPhone') ? localStorage.getItem('oneClickPhone') : '',
      tipMessage: '',
      tipMessageType: '',
      tipShow: false,
      // 栏目数据
      categorys: [],
      categoryIdIndex: Number(window.localStorage.getItem('categoryIdIndex')) || 0,
      // 订购所需数据
      settingData: {
        videoId: '',
        productId: '',
        copyRightID: '',
        contentId: '',
        sourceType: '',
        searchkey: '',
        desc: ''
      },
      showList: [],
      showCuccList: [],
      changePhone: false,
      errorMessage: ''
    }
  },
  mutations: {
    SET_ERROR_MESSAGE: (state, errorMessage) => {
      state.errorMessage = errorMessage
    },
    SET_RINGTONE_TYPE: (state, data) => {
      state.ringStatusType = data
    },
    // 运营商
    SET_CARRIER_TYPE: (state, data) => {
      state.carrierType = data
      window.localStorage.setItem('userType', data)
    },
    // 当前播放彩铃id
    SET_CURRENT_RING_ID: (state, data) => {
      state.currentRingId = data
    },
    // 一键登录获取手机号
    SET_ONE_CLICK_PHONE: (state, data) => {
      state.oneClickPhone = data
      state.phone = data
      window.localStorage.setItem('oneClickPhone', data)
    },
    // 当前使用的手机号
    SET_PHONE: (state, data) => {
      // 如果清除了 则将手机号设置为一键登录的手机号
      if (!data && state.oneClickPhone) {
        state.phone = state.oneClickPhone
      } else {
        state.phone = data
      }
    },
    SET_TIPSHOW: (state, isShow) => {
      state.tipShow = isShow
    },
    SET_TIP_MESSAGE: (state, msg) => {
      state.tipMessage = msg
    },
    SET_TIP_MESSAGE_TYPE: (state, type) => {
      state.tipMessageType = type
    },
    // 底纹
    SET_HINTS_TEXT: (state, text) => {
      state.searchHintsText = text
    },
    // 搜索热词
    SET_HOT_TEXT: (state, texts) => {
      state.searchHotTexts = texts
    },
    // 删除历史记录
    CLEAR_HISTORY: (state, texts) => {
      state.searchhistory.splice(0)
      localStorage.removeItem('histroy')
    },
    // 更新搜索历史记录
    UPDATE_HISTORY: (state, searchValue) => {
      // 先找有没有存在,存在挪到第一位
      const index = state.searchhistory.findIndex((item) => item === searchValue)
      if (index !== -1) {
        const [item] = state.searchhistory.splice(index, 1)
        state.searchhistory.unshift(item)
      } else {
        // 不存在
        // 判断是否超出10个 超出十个 删除最后一个,将新的推到前面
        if (state.searchhistory.length < 10) {
          state.searchhistory.unshift(searchValue)
        } else {
          state.searchhistory.pop()
          state.searchhistory.unshift(searchValue)
        }
      }
      localStorage.setItem('histroy', JSON.stringify(state.searchhistory))
    },
    // 更新栏目分类
    SET_CATEGORYS (state, categorys) {
      state.categorys.splice(0)
      state.categorys.push(...categorys)
    },
    SET_CATEGORYS_ID_INDEX (state, categoryIdIndex) {
      state.categoryIdIndex = categoryIdIndex
    },
    // 更换手机号
    SET_CHANGE_PHONE (state, changePhone) {
      state.changePhone = changePhone
    },
    // 设置彩铃信息
    SET_SETTING_DESC (state, desc) {
      state.settingData.desc = desc
    },
    SET_SETTING_SOURCETYPE (state, sourceType) {
      state.settingData.sourceType = sourceType
    },
    SET_SETTING_VIDEOID (state, ringId) {
      state.settingData.videoId = ringId
    },
    SET_SETTING_PRODUCT_ID (state, productId) {
      state.settingData.productId = productId
    },
    SET_SETTING_CONTENT_ID (state, contentId) {
      state.settingData.contentId = contentId
    },
    SET_SETTING_CROPYRINGHT_ID (state, copyRightID) {
      state.settingData.copyRightID = copyRightID
    },
    SET_SHOWLIST (state, showList) {
      state.showList.splice(0)
      state.showList.push(...showList)
    },
    SET_CUCC_SHOWLIST (state, showList) {
      state.showCuccList.splice(0)
      state.showCuccList.push(...showList)
    }
  },
  actions: {
    getCuccList ({ dispatch, commit, state }, payload) {
      sourceApi
        .queryTopic({ subjectId: payload, phone: '13161770390', sourceType: 2 })
        .then((res) => {
          commit(
            'SET_CUCC_SHOWLIST',
            res.data.ringContentVOList.map((item) => {
              return {
                sourceType: item.ispType,
                id: item.ringId,
                desc: item.ringName,
                homePosterSrc: item.homePreviewPath,
                title: item.ringName,
                posterSrc: item.homePreviewPath,
                videoSrc: item.filePath
              }
            })
          )
        })
        .catch(() => {})
    },
    getTelecomList ({ dispatch, commit, state }, payload) {
      sourceApi
        .queryTopic({ subjectId: payload, phone: '17310075030' })
        .then((res) => {
          commit(
            'SET_SHOWLIST',
            res.data.ringContentVOList.map((item) => {
              return {
                sourceType: item.ispType,
                id: item.ringId,
                desc: item.ringName,
                homePosterSrc: item.homePreviewPath,
                title: item.ringName,
                posterSrc: item.homePreviewPath,
                videoSrc: item.filePath
              }
            })
          )
        })
        .catch(() => {})
    },
    getCarrierType ({ dispatch, commit, state }, payload) {
      dispatch('checkMobileTrack', { type: '10', res: '', isSuccess: false, carrierName: '' })
      return new Promise((resolve, reject) => {
        sourceApi
          .checkMobile({
            phone: state.phone
          })
          .then((res) => {
            commit('SET_CARRIER_TYPE', res.data)
            dispatch('checkMobileTrack', {
              type: '11',
              res,
              isSuccess: res.code === '0000',
              carrierName: CarrierType.MIGU
            })
            resolve(res)
          })
          .catch((err) => {
            dispatch('checkMobileTrack', { type: '11', res: err, isSuccess: false, carrierName: '' })
            reject(err)
          })
      })
    },
    checkMobileTrack ({ commit }, payload) {
      const { type, res, isSuccess, carrierName } = payload
      const params = {
        h5_color_ring_order_api_event_type: type,
        is_success: isSuccess || false,
        error_code: !isSuccess ? res.code || res.resCode || res.resultCode : '',
        error_msg: !isSuccess ? res.message || res.resMsg || res.msg : '',
        carrier_name: ''
      }
      if (carrierName) {
        params.carrier_name = carrierName === '3' ? '中国移动' : res.data === '2' ? '中国联通' : '中国电信'
      }
      track([{ event_id: 'h5_color_ring_order_api_event', event_param: params }])
    },
    // 设置前的判断
    settingPrepareCucc ({ state, dispatch, commit }, payload) {
      sourceApi
        .checkMobile({
          phone: state.phone,
          pcid: Router.currentRoute.query.pcid
        })
        .then((res) => {
          commit('SET_CARRIER_TYPE', res.data.type)
          switch (res.data.type) {
            case CarrierType.TELECOM:
            case CarrierType.MIGU: {
              commit('SET_ERROR_MESSAGE', '请使用联通手机号开通')
              Toast.fail({
                message: '请使用联通手机号开通',
                forbidClick: true,
                duration: 1000
              })
              commit('SET_PHONE', '')
              break
            }
            case CarrierType.CUCC: {
              dispatch('orderCucc', false)
              break
            }
          }
        })
        .catch(() => {})
    },
    // 设置前的判断
    settingPrepare ({ state, dispatch, commit }, payload) {
      const currentType = payload
      return new Promise((resolve, reject) => {
        sourceApi
          .checkMobile({
            phone: state.phone,
            pcid: Router.currentRoute.query.pcid
          })
          .then(res => {
            resolve(res)

            dispatch('handleActionApi', {
              isp: '未判断',
              token: '',
              phone: payload,
              action: '11',
              interfaceName: 'api/checkMobile',
              resCode: res.code,
              resMessage: JSON.stringify(res)
            })
            // debugger
            // 异常处理
            if (res.code === '5000') {
              Toast.fail({
                message: res.message
              })
              // dispatch('checkMobileTrack', {
              //   type: '11',
              //   res,
              //   isSuccess: res.j === '0000',
              //   carrierName: ''
              // })
              dispatch('handleActionApi', {
                isp: '',
                token: '',
                phone: payload,
                action: '11',
                interfaceName: 'api/checkMobile',
                resCode: res.code,
                resMessage: JSON.stringify(res)
              })
              reject(res.message)
            } else {
            // 正常返回。但针对广电提醒 && (status === 1)
              const { type, detail, status, mobile } = res.data

              let message = ''
              if (status === 1) {
                message = `${detail[0] ? `${detail[0]}` : ''}-type:${type}-status:${status}-phone:${mobile}`
              } else {
                message = `${detail[0] ? `${detail[0]}省份限制` : ''},手机号:${mobile}-type:${type}-status:${status}`
              }
              if (currentType !== type) {
                message = `请使用请使用${CarrierType.ENUM[currentType]}号手机号开通`
                Toast.fail({
                  message
                })
                reject(res.message)
              }
              // dispatch('checkMobileTrack', {
              //   type: '11',
              //   res: message,
              //   isSuccess: (res.code === '0000'),
              //   carrierName: type
              // })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[type],
                token: '',
                phone: payload,
                action: '11',
                interfaceName: 'api/checkMobile',
                resCode: res.code,
                resMessage: JSON.stringify(res)
              })
              if (type === CarrierType.CBN) {
                Toast.fail({
                  message: '快换移动号，广电设置不了'
                })
                reject(res.message)
              } else if (status === 1) {
                const isSame = type === state.carrierType
                commit('SET_CARRIER_TYPE', type)
                // commit('SET_PHONE_PROVINCE', detail[0])
                resolve({ type, isSame })
              } else {
                const message = `抱歉，您所在的区域${detail[0] ? `(${detail[0]})` : ''}暂时不支持使用该视频彩铃服务，敬请谅解！`
                Toast.fail({
                  message
                })
                reject(res.message)
              }
            }
          }).catch((err) => {
            Toast.clear()
            // dispatch('checkMobileTrack', { type: '11', res: err.message, isSuccess: false, carrierName: '' })
            dispatch('handleActionApi', {
              isp: '',
              token: '',
              phone: payload,
              action: '11',
              interfaceName: 'api/checkMobile',
              resCode: 'err',
              resMessage: `catcherr ${err.message}`
            })
          })
      })
    },
    getPackageList ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        sourceApi
          .packageList({ ...payload })
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    handleActionApi ({ commit, rootState }, data) {
      sourceApi.actionlogApi({
        ...data,
        pid: Router.currentRoute.query.clickid,
        uid: Router.currentRoute.query.clickid
      })
    },
    // 电信订阅
    async orderTelecom ({ commit, state, dispatch, rootState }, isCurrentPageSetting) {
      // ProcessDialog()
      return new Promise((resolve, reject) => {
        Toast.clear()
        const mobile = state.phone
        const curerntPageParams = parseUrlParams(location.href)
        curerntPageParams.videoId = state.settingData.videoId
        curerntPageParams.copyRightID = state.settingData.copyRightID
        curerntPageParams.productId = state.settingData.productId
        curerntPageParams.pcid = Router.currentRoute.query.pcid
        const returnUrl = window.location.href.split('?')[0] + '?' + serializeUrlParams(curerntPageParams)

        // await dispatch('user/getUserToken', state.phone, { root: true })
        // dispatch('checkMobileTrack', { type: '26', res: '', isSuccess: false, carrierName: CarrierType.TELECOM })
        dispatch('handleActionApi', {
          isp: CarrierType.ENUM[CarrierType.TELECOM],
          phone: state.phone,
          action: '26',
          interfaceName: '/api/telecom/package/list',
          resCode: '',
          resMessage: ''
        })
        dispatch('getPackageList', { mobile: state.phone, promotionChannel: Router.currentRoute.query.pcid }).then((res) => {
          // dispatch('checkMobileTrack', { type: '27', res, isSuccess: res.code === '0000', carrierName: CarrierType.TELECOM })
          dispatch('handleActionApi', {
            isp: CarrierType.ENUM[CarrierType.TELECOM],
            phone: state.phone,
            action: '27',
            interfaceName: '/api/telecom/package/list',
            resCode: res.code,
            resMessage: JSON.stringify(res)
          })
          // const presetProperties = sensors.getPresetProperties()
          let status = null
          const packageId = state.settingData.productId
          res.data?.forEach((element) => {
            if (element.package_id === packageId) {
              status = element.status
            }
          })
          if (status === PackageStatusType.Subscribed) {
            Toast.fail({
              message: '您已开通！',
              forbidClick: true,
              duration: 1500
            })
            reject(new Error('您已开通！'))

            dispatch('handleActionApi', {
              isp: CarrierType.ENUM[CarrierType.TELECOM],
              phone: state.phone,
              action: '28',
              interfaceName: '/api/telecom/openOrder',
              resCode: '',
              resMessage: '您已开通！'
            })

            // 1.一键登录的手机号是当前使用的 手机号则不需要验证码
            // 2.没确认登陆但是已经书输入过验证码
            // 3.已经确认登录 则不需要验证码
          } else {
            // 没有定
            // // H5 计费下单发起接口（一键开户）
            dispatch('handleActionApi', {
              isp: CarrierType.ENUM[CarrierType.TELECOM],
              phone: state.phone,
              action: '28',
              interfaceName: '/api/telecom/openOrder',
              resCode: '',
              resMessage: ''
            })

            // dispatch('checkMobileTrack', { type: '28', res: '', isSuccess: false, carrierName: CarrierType.TELECOM })
            sourceApi
              .openOrder({
                mobile,
                returnUrl,
                promotionChannel: Router.currentRoute.query.pcid,
                productId: state.settingData.productId,
                verifyType: 1,
                isMessage: 1
              })
              .then((res) => {
                // dispatch('checkMobileTrack', { type: '29', res, isSuccess: res.resCode === '0000', carrierName: CarrierType.TELECOM })
                dispatch('handleActionApi', {
                  isp: CarrierType.ENUM[CarrierType.TELECOM],
                  phone: state.phone,
                  action: '29',
                  interfaceName: '/api/telecom/openOrder',
                  resCode: res.code,
                  resMessage: JSON.stringify(res)
                })

                Toast.clear()
                if (res.code === '0000') {
                  if (res.data) {
                    // track([{ event_id: 'h5_color_ring_link_order_page_success' }])
                    window.sessionStorage.setItem(
                      'addtoneFreeParams',
                      JSON.stringify({
                        phoneNumber: mobile,
                        toneCode: state.settingData.videoId,
                        contentId: state.settingData.videoId,
                        contentName: state.settingData.desc
                      })
                    )

                    window.localStorage.setItem('productId', state.settingData.productId)
                    // 先存到session中方便会挑时恢复状态
                    window.sessionStorage.setItem('carrierType', state.carrierType)
                    // 跳转之前先把token phone信息 先存到session中方便会挑时恢复状态
                    window.localStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))
                    window.sessionStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))
                    // 储存历史页面记录
                    window.sessionStorage.setItem('historyPage', JSON.stringify(state.historyPage))
                    if (Router.currentRoute.query.searchkey) {
                      window.sessionStorage.setItem('searchList', JSON.stringify(rootState.ringtone.searchList))
                    }
                    // 综搜跟播放页使用过来的
                    if (Pcid.Apps.includes(Router.currentRoute.query.pcid)) {
                      window.sessionStorage.setItem(
                        'importTypeDataList',
                        JSON.stringify(rootState.appStore.importTypeDataList)
                      )
                    }

                    // 切换资源的内容
                    if (Router.currentRoute.query.from === 'changesource') {
                      window.sessionStorage.setItem(
                        'changeSourceList',
                        JSON.stringify(rootState.appStore.changeSourceList)
                      )
                    }
                    // 二次确认开通
                    //   window.location.href = res.data.feeUrl
                    resolve(res.data.feeUrl)
                  } else {
                    reject(res.message)
                    Toast.fail(res.message)
                  }
                } else {
                  reject(res.message)
                  // this.loginDialog = true
                }
              })
              .catch((err) => {
                Toast.clear()
                dispatch('handleActionApi', {
                  isp: CarrierType.ENUM[CarrierType.TELECOM],
                  phone: state.phone,
                  action: '29',
                  interfaceName: '/api/telecom/openOrder',
                  resCode: 'err',
                  resMessage: JSON.stringify(err)
                })
              })
          }
        })
      })
    },
    // 联通订购
    async orderCucc ({ commit, state, dispatch, rootState, getters }, payload) {
      Toast.loading({ forbidClick: true, duration: 1500 })
      return new Promise((resolve, reject) => {
        const curerntPageParams = parseUrlParams(location.href)
        curerntPageParams.videoId = state.settingData.videoId
        curerntPageParams.finsh = true
        const returnUrl = window.location.href.split('?')[0] + '?' + serializeUrlParams(curerntPageParams)
        console.log(returnUrl.length)
        // await dispatch('user/getUserToken', state.phone, { root: true })
        // // 查询是否订购
        // dispatch('checkMobileTrack', { type: '35', res: '', isSuccess: false, carrierName: CarrierType.CUCC })
        dispatch('handleActionApi', {
          isp: CarrierType.ENUM[CarrierType.CUCC],
          phone: state.phone,
          action: '35',
          interfaceName: '/qrySubedProductsNoToken',
          resCode: '',
          resMessage: ''
        })
        sourceApi.qrySubedProductsNoToken({ promotionChannel: Router.currentRoute.query.pcid, phone: state.phone }).then((res) => {
        // dispatch('checkMobileTrack', {
        //   type: '36',
        //   res,
        //   isSuccess: res.code === '0000',
        //   carrierName: CarrierType.CUCC
        // })
          dispatch('handleActionApi', {
            isp: CarrierType.ENUM[CarrierType.CUCC],
            phone: state.phone,
            action: '36',
            interfaceName: '/qrySubedProductsNoToken',
            resCode: '',
            resMessage: ''
          })
          // 没开通
          // debugger
          if (res.data !== CuccStatusType.OrderFinsh) {
          // window.localStorage.setItem('return', returnUrl)
            sourceApi
              .onePointProductMon({ phone: state.phone, redirectUrl: returnUrl, ringId: state.settingData.videoId, promotionChannel: Router.currentRoute.query.pcid })
              .then((resO) => {
              // 开通
              // dispatch('checkMobileTrack', {
              //   type: '38',
              //   res: resO,
              //   isSuccess: resO.code === '0000',
              //   carrierName: CarrierType.CUCC
              // })
                dispatch('handleActionApi', {
                  isp: CarrierType.ENUM[CarrierType.CUCC],
                  phone: state.phone,
                  action: '38',
                  interfaceName: '/onePointProductMon',
                  resCode: resO.code,
                  resMessage: JSON.stringify(resO)
                })

                Toast.clear()
                if (resO.code === '0000') {
                  if (resO.data) {
                    resolve(resO.data.url)
                  }
                } else {
                  commit('SET_ERROR_MESSAGE', resO.message)
                  Toast.fail(resO.message)
                }
              })
              .catch((err) => {
                dispatch('checkMobileTrack', {
                  type: '38',
                  res: JSON.stringify(err),
                  isSuccess: false,
                  carrierName: CarrierType.CUCC
                })
                dispatch('handleActionApi', {
                  isp: CarrierType.ENUM[CarrierType.CUCC],
                  token: rootState.app.userInfo.TOKEN,
                  phone: rootState.app.userInfo.phone,
                  action: '38',
                  interfaceName: '/onePointProductMon',
                  resCode: 'err',
                  resMessage: JSON.stringify(err)
                })
              })
          } else {
            // 开通了
            reject(Error('您已经开通！'))
            Toast.fail('您已经开通！')
            dispatch('handleActionApi', {
              isp: CarrierType.ENUM[CarrierType.CUCC],
              phone: state.phone,
              action: '38',
              interfaceName: '/onePointProductMon',
              resMessage: '您已经开通！'
            })
          }
        })
      })
    }
    // dispatch('orderCucc', false)

  }
}

export default ringTone
