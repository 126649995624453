
import interfaces from '../interface/index'
import request from '@/request/index.js'
import qs from 'qs'
const user = {
  login (data) {
    return request.post(`${interfaces.login}`, qs.stringify(data))
  },
  sendMsg (data) {
    return request.post(`${interfaces.sendMsg}`, qs.stringify(data))

    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve({ code: '0000', message: 'ok', data: null })
    //   }, 1000)
    // })
  },
  loginSecret (data) {
    return request.post(`${interfaces.loginSecret}`, qs.stringify(data))
  },
  getUserPhone (data) {
    return request.post(`${interfaces.getUserPhone}`, data)
  },
  newLogin (data) {
    return request.post(`${interfaces.newLogin}`, qs.stringify(data))
  }
}

export default user
