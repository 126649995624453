import { Toast } from 'vant'
import Router from '@/router'
import sourceApi from '@/api/modules/source'
import CarrierType from '@/model/CarrierType'
import CuccStatusType from '@/model/CuccStatusType'
import Pcid from '@/model/PcidType'
import CustomeDialog from '@/components/dialog'
import { exitPage } from '@/utils/bridge.js'
import { track } from '@/directives/track/send.js'
import { deeplinkto, parseUrlParams, serializeUrlParams } from '@/utils/index'
const sensors = window.sensorsDataAnalytic201505
export const actions = {
  // 联通订购
  async orderCucc ({ commit, state, dispatch, rootState, getters }, payload) {
    debugger
    let isCurrentPageSetting = false
    let openOrder = false
    let openNew = false
    let isNoComfirn = false
    console.log(openOrder)
    if (payload) {
      isCurrentPageSetting = payload.isCurrentPageSetting
      openOrder = !!payload.openOrder
      openNew = !!payload.openNew
      isNoComfirn = !!payload.isNoComfirn
    }
    Toast.loading({ forbidClick: true, duration: 1500 })

    const curerntPageParams = parseUrlParams(location.href)

    delete curerntPageParams.childOf
    delete curerntPageParams.language
    delete curerntPageParams.version
    delete curerntPageParams.versionCode
    delete curerntPageParams.nav_height
    delete curerntPageParams.bgColor
    delete curerntPageParams.dark
    delete curerntPageParams.api_version
    delete curerntPageParams.contentId
    delete curerntPageParams.desc
    delete curerntPageParams.sourceID
    delete curerntPageParams.referrer
    delete curerntPageParams.sourceName

    curerntPageParams.videoId = state.settingData.videoId
    curerntPageParams.finsh = true
    const host = window.location.href.split('?')[0]
    const isRingIdEmpty = (!curerntPageParams.videoId) // 彩铃id是否为空
    const isCorrectId = curerntPageParams.videoId && (curerntPageParams.videoId.length !== 23) // 彩铃id是否是联通id
    if ((isRingIdEmpty || isCorrectId) && rootState.app.configData.backup_ringid_cucc) {
      const backupid = rootState.app.configData.backup_ringid_cucc
      commit('SET_SETTING_VIDEOID', backupid)
      curerntPageParams.videoId = backupid

      const clickTrackParam2 = {
        content_id: state.settingData.videoId,
        content_name: state.settingData.desc,
        $element_content: '设为彩铃（内容）',
        $element_id: 'next-step',
        $element_type: 'div'
      }
      clickTrackParam2.content_id = backupid + '(空)'
      clickTrackParam2.content_name = '(空)'
      track([{ event_id: '$WebClick', event_param: clickTrackParam2 }])
    }

    let returnUrl = host.split('#')[0] + '#/details' + '?' + serializeUrlParams(curerntPageParams)
    const temobj = JSON.parse(JSON.stringify(curerntPageParams))
    if (returnUrl.length > 470) {
      do {
        if (temobj.title) {
          temobj.title = temobj.desc.slice(0, temobj.desc.length - 3)
        }
        if (temobj.actorName) {
          temobj.actorName = temobj.actorName.slice(0, temobj.actorName.length - 3)
        }
        if (temobj.songName) {
          temobj.songName = temobj.songName.slice(0, temobj.songName.length - 3)
        }
        returnUrl = host.split('#')[0] + '#/details' + '?' + serializeUrlParams(temobj)
      } while (returnUrl.length > 470)
    }
    const presetProperties = sensors.getPresetProperties()
    const cookieId = presetProperties.identities.$identity_cookie_id

    console.log(returnUrl.length)
    await dispatch('user/getUserToken', { phone: state.phone }, { root: true })
    if (Router.currentRoute.query.tabType === 'tab-home') {
      // window.close()
      const query = {
        phone: state.phone,
        token: rootState.app.userInfo.TOKEN,
        carrierType: state.carrierType,
        sourceChange: true,
        sourceChangeConfirm: true,
        loginStatus: rootState.app.loginStatus,
        date: new Date().getTime()
      }
      window.localStorage.setItem('tag-home-fresh', JSON.stringify(query))
      console.log('prepare', JSON.stringify(query))
    }
    // 查询是否订购
    dispatch('checkMobileTrack', { type: '35', res: '', isSuccess: false, carrierName: CarrierType.CUCC })
    dispatch('handleActionApi', {
      isp: CarrierType.ENUM[CarrierType.CUCC],
      token: rootState.app.userInfo.TOKEN,
      phone: rootState.app.userInfo.phone,
      action: '35',
      interfaceName: '/qrySubedProductsNoToken',
      resCode: '',
      resMessage: ''
    })
    sourceApi.qrySubedProductsNoToken({ phone: state.phone }).then((res) => {
      dispatch('checkMobileTrack', {
        type: '36',
        res,
        isSuccess: res.code === '0000',
        carrierName: CarrierType.CUCC
      })
      dispatch('handleActionApi', {
        isp: CarrierType.ENUM[CarrierType.CUCC],
        token: rootState.app.userInfo.TOKEN,
        phone: rootState.app.userInfo.phone,
        action: '36',
        interfaceName: '/qrySubedProductsNoToken',
        resCode: '',
        resMessage: ''
      })
      // 没开通 (已退订，次月停等)
      if (res.data !== CuccStatusType.OrderFinsh && res.data !== CuccStatusType.OrderNextMonthSotp) {
        if (
          (isCurrentPageSetting && // 当前设置
            !state.sourceChange && // 没有资源切换
            !!(window.KuYinExt && window.KuYinExt.requestAuthorizationAs) && // 欢太未登录
            !openOrder && !(Pcid.Apps.includes(Router.currentRoute.query.pcid)) && (rootState.appStore.showDetailStyle !== 'b')) || // 不是直接打开订购
          ((Router.currentRoute.name !== 'confirm-phone' && rootState.app.processConfig[2].page) && !(rootState.app.configData.no_choice_page === '1') && !isNoComfirn && !(Pcid.Apps.includes(Router.currentRoute.query.pcid)) && (rootState.appStore.showDetailStyle !== 'b')) ||
          ((Router.currentRoute.name !== 'inputphone-oneclick' && rootState.app.processConfig[2].page) && !!(rootState.app.configData.no_choice_page === '1') && !isNoComfirn && !(Pcid.Apps.includes(Router.currentRoute.query.pcid)) && (rootState.appStore.showDetailStyle !== 'b')) // 播放渠道搜索渠道 并且播放样式不是b
        ) {
          const query = {
            ...Router.currentRoute.query,
            ...state.settingData
          }
          if (Number(Router.currentRoute.query.status_height) > 0) {
            query.status_height = Router.currentRoute.query.status_height
          }
          if (Router.currentRoute.name === 'input-phone') {
            if (openNew) {
              delete query.header_type
              delete query.status_height
              query.tabType = 'tab-home'

              if (rootState.app.configData.tab_home_setting_page === '1') {
                deeplinkto('/details', query, '0')
                return
              }

              if (rootState.app.configData.no_choice_page === '1') {
                deeplinkto('/inputphone-oneclick', query)
                return
              }
              deeplinkto('/confirm-phone', query)
            } else {
              if (rootState.app.configData.no_choice_page === '1') {
                Router.push({
                  name: 'inputphone-oneclick',
                  query,
                  params: {
                    isPush: true
                  }
                })
                return
              }
              Router.replace({
                name: 'confirm-phone',
                query,
                params: {
                  isPush: true
                }
              })
            }
          } else {
            if (openNew) {
              delete query.header_type
              delete query.status_height
              query.tabType = 'tab-home'
              if (rootState.app.configData.tab_home_setting_page === '1') {
                deeplinkto('/details', query, '0')
                return
              }

              if (rootState.app.configData.no_choice_page === '1') {
                deeplinkto('/inputphone-oneclick', query)
                return
              }
              deeplinkto('/confirm-phone', query)
            } else {
              if (rootState.app.configData.no_choice_page === '1') {
                Router.push({
                  name: 'inputphone-oneclick',
                  query,
                  params: {
                    isPush: true
                  }
                })
                return
              }
              Router.push({
                name: 'confirm-phone',
                query,
                params: {
                  isPush: true
                }
              })
            }
          }
        } else {
          dispatch('checkMobileTrack', { type: '37', res: '', isSuccess: false, carrierName: CarrierType.CUCC })
          dispatch('handleActionApi', {
            isp: CarrierType.ENUM[CarrierType.CUCC],
            token: rootState.app.userInfo.TOKEN,
            phone: rootState.app.userInfo.phone,
            action: '37',
            interfaceName: '/onePointProductMon',
            resCode: '',
            resMessage: ''
          })

          dispatch('handleActionApi', {
            isp: CarrierType.ENUM[CarrierType.CUCC],
            token: rootState.app.userInfo.TOKEN,
            phone: rootState.app.userInfo.phone,
            action: '37',
            interfaceName: '/查看数据的id' + state.settingData.videoId,
            resCode: '',
            resMessage: ''
          })

          // http://localhost:8081/#/details?sourceType=3&videoId=70760000202407163842170&tip=true&categoryTitle=%E7%83%AD%E9%97%A8&categoryId=47&copyRightID=699052TBM6PM&finsh=true
          console.log({ phone: state.phone, redirectUrl: returnUrl, ringId: state.settingData.videoId })
          // window.localStorage.setItem('return', returnUrl)
          sourceApi
            .onePointProductMon({
              phone: state.phone,
              redirectUrl: returnUrl,
              ringId: state.settingData.videoId,
              promotionChannel: Router.currentRoute.query.pcid,
              pid: rootState.app.selfInfo['dev-pid'],
              uid: rootState.app.selfInfo.uid,
              cookieId: cookieId
            })
            .then((resO) => {
              // 开通
              dispatch('checkMobileTrack', {
                type: '38',
                res: resO,
                isSuccess: resO.code === '0000',
                carrierName: CarrierType.CUCC
              })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.CUCC],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '38',
                interfaceName: '/onePointProductMon',
                resCode: resO.code,
                resMessage: JSON.stringify(resO)
              })

              Toast.clear()
              if (resO.code === '0000') {
                sourceApi.channelAdd({
                  mobile: state.phone,
                  type: ~~CarrierType.CUCC,
                  promotionChannel: Router.currentRoute.query.pcid, // 推广渠道
                  pid: rootState.app.selfInfo['dev-pid'],
                  uid: rootState.app.selfInfo.uid,
                  cookieId: cookieId
                })
                if (resO.data) {
                  console.log(rootState)
                  if (!rootState.app.processConfig[2].dialog) {
                    track([{ event_id: 'h5_color_ring_link_order_page_success', event_param: { content_name: CarrierType.ENUM[CarrierType.CUCC], carrier_name: CarrierType.ENUM[CarrierType.CUCC] } }])
                    window.sessionStorage.setItem(
                      'addtoneFreeParams',
                      JSON.stringify({
                        phoneNumber: state.phone,
                        toneCode: state.settingData.videoId,
                        contentId: state.settingData.videoId,
                        contentName: state.settingData.desc
                      })
                    )
                    window.sessionStorage.setItem('processConfig', JSON.stringify(rootState.app.processConfig))
                    // 先存到session中方便会挑时恢复状态
                    window.sessionStorage.setItem('carrierType', state.carrierType)
                    // 跳转之前先把token phone信息 先存到session中方便会挑时恢复状态
                    window.sessionStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))
                    // 储存历史页面记录
                    if (Router.currentRoute.query.searchkey) {
                      window.sessionStorage.setItem('searchList', JSON.stringify(rootState.appStore.searchList))
                      window.sessionStorage.setItem('searchParams', JSON.stringify(rootState.appStore.searchParams))
                    }
                    // 综搜跟播放页使用过来的
                    if (Pcid.Apps.includes(Router.currentRoute.query.pcid)) {
                      window.sessionStorage.setItem(
                        'importTypeDataList',
                        JSON.stringify(rootState.appStore.importTypeDataList)
                      )
                    }
                    // 切换资源的内容
                    if (JSON.stringify(rootState.appStore.changeSourceList) !== '[]') {
                      window.sessionStorage.setItem(
                        'changeSourceList',
                        JSON.stringify(rootState.appStore.changeSourceList)
                      )
                    }

                    // tab二级内容
                    if (JSON.stringify(rootState.appStore.tabHomeListQuery) !== '[]') {
                      window.sessionStorage.setItem(
                        'tabHomeListQuery',
                        JSON.stringify(rootState.appStore.tabHomeListQuery)
                      )
                    }

                    // tab二级内容
                    if (JSON.stringify(rootState.appStore.tabHomeList) !== '[]') {
                      window.sessionStorage.setItem(
                        'tabHomeList',
                        JSON.stringify(rootState.appStore.tabHomeList)
                      )
                    }

                    // 分类资源
                    if (JSON.stringify(state.categorys) !== '[]') {
                      window.sessionStorage.setItem('categorys', JSON.stringify(state.categorys))
                    }

                    // 刷新随机资源
                    if (JSON.stringify(rootState.appStore.randomList) !== '[]') {
                      window.sessionStorage.setItem('randomList', JSON.stringify(rootState.appStore.randomList))
                    }
                    window.sessionStorage.setItem(
                      'cancelOrder',
                      JSON.stringify({ ...state.settingData, sourceType: state.carrierType, phone: state.phone })
                    )
                    window.location.href = resO.data.url
                  } else {
                    CustomeDialog({ phone: state.phone, rules: rootState.app.processConfig[2].rules })
                      .then(() => {
                        track([{ event_id: 'h5_color_ring_link_order_page_success', event_param: { content_name: CarrierType.ENUM[CarrierType.CUCC], carrier_name: CarrierType.ENUM[CarrierType.CUCC] } }])
                        window.sessionStorage.setItem(
                          'addtoneFreeParams',
                          JSON.stringify({
                            phoneNumber: state.phone,
                            toneCode: state.settingData.videoId,
                            contentId: state.settingData.videoId,
                            contentName: state.settingData.desc
                          })
                        )
                        // 先存到session中方便会挑时恢复状态
                        window.sessionStorage.setItem('processConfig', JSON.stringify(rootState.app.processConfig))
                        // 先存到session中方便会挑时恢复状态
                        window.sessionStorage.setItem('carrierType', state.carrierType)
                        // 跳转之前先把token phone信息 先存到session中方便会挑时恢复状态
                        window.sessionStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))
                        // 储存历史页面记录
                        if (Router.currentRoute.query.searchkey) {
                          window.sessionStorage.setItem('searchList', JSON.stringify(rootState.ringtone.searchList))
                          window.sessionStorage.setItem('searchParams', JSON.stringify(rootState.ringtone.searchParams))
                        }
                        // 综搜跟播放页使用过来的
                        if (Pcid.Apps.includes(Router.currentRoute.query.pcid)) {
                          window.sessionStorage.setItem(
                            'importTypeDataList',
                            JSON.stringify(rootState.appStore.importTypeDataList)
                          )
                        }
                        // 切换资源的内容
                        if (JSON.stringify(rootState.appStore.changeSourceList) !== '[]') {
                          window.sessionStorage.setItem(
                            'changeSourceList',
                            JSON.stringify(rootState.appStore.changeSourceList)
                          )
                        }
                        // 分类资源
                        if (JSON.stringify(state.categorys) !== '[]') {
                          window.sessionStorage.setItem('categorys', JSON.stringify(state.categorys))
                        }

                        // 刷新随机资源
                        if (JSON.stringify(rootState.appStore.randomList) !== '[]') {
                          window.sessionStorage.setItem('randomList', JSON.stringify(rootState.appStore.randomList))
                        }
                        window.sessionStorage.setItem(
                          'cancelOrder',
                          JSON.stringify({ ...state.settingData, sourceType: state.carrierType, phone: state.phone })
                        )
                        window.location.href = resO.data.url
                      })
                      .catch(() => {
                        Toast('用户取消操作')
                      })
                  }
                } else {
                  Toast.fail(resO.message)
                }
              } else if (resO.code === '3001') {
                Toast.fail('登录过期，请重新登录')
                // this.loginDialog = true
              } else if (resO.code === '5000') {
                if (!rootState.app.loginStatus) {
                  commit('app/SET_USERINFO', {}, { root: true })
                  // commit('SET_PHONE', '')
                  // commit('SET_CARRIER_TYPE', CarrierType.MIGU)
                }
                Toast.fail(resO.message)
              } else {
                Toast.fail(resO.message)
                if (!rootState.app.loginStatus) {
                  commit('app/SET_USERINFO', {}, { root: true })
                  // commit('SET_PHONE', '')
                  // commit('SET_CARRIER_TYPE', CarrierType.MIGU)
                }
              }
            })
            .catch((err) => {
              dispatch('checkMobileTrack', {
                type: '38',
                res: JSON.stringify(err),
                isSuccess: false,
                carrierName: CarrierType.CUCC
              })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.CUCC],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '38',
                interfaceName: '/onePointProductMon',
                resCode: 'err',
                resMessage: JSON.stringify(err)
              })
            })
        }
      } else {
        // 开通了
        window.sessionStorage.setItem('test', '222')
        if (
          (state.oneClickPhone && state.oneClickPhone === state.phone) ||
          (rootState.app.codeValidate &&
            !rootState.app.loginStatus &&
            JSON.stringify(rootState.app.userInfo) !== '{}') ||
          rootState.app.loginStatus
        ) {
          // 未确认登录需要 输入验证码后确认
          const phoneNumber = state.phone

          sourceApi
            .settingRingOnePointMon2({
              phone: phoneNumber,
              ringId: state.settingData.videoId,
              promotionChannel: Router.currentRoute.query.pcid,
              pid: rootState.app.selfInfo['dev-pid'],
              uid: rootState.app.selfInfo.uid,
              cookieId: cookieId
            })
            .then((res) => {
              dispatch('checkMobileTrack', {
                type: '30',
                res,
                isSuccess: res.code === '0000',
                carrierName: CarrierType.TELECOM
              })
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.CUCC],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '30',
                interfaceName: '/settingRingOnePointMon',
                resCode: res.code,
                resMessage: JSON.stringify(res)
              })
              Toast.clear()
              if (res.code === '0000') {
                window.localStorage.setItem('userInfo', JSON.stringify(rootState.app.userInfo))

                commit('SET_PHONE', phoneNumber)
                commit('app/SET_LOGIN_STATUS', true, { root: true })
                dispatch('loginConfirm')
                Toast.success({
                  message: '设置成功',
                  forbidClick: true,
                  duration: 1500,
                  onClose: () => {
                    if (Router.currentRoute.query.tabType === 'tab-home') {
                      exitPage()
                    }
                    if (!isCurrentPageSetting) {
                      const pageCount = state.historyPage.length ? state.historyPage.length : 1
                      if (Router.currentRoute.query.order_no) {
                        Router.go(-(pageCount + 3)) // 电信订购成功之后直接跳回我们页面。此时订购成功回退的时候需要多回跳2个页面
                      } else {
                        Router.go(-pageCount)
                      }
                    }
                  }
                })
              } else {
                Toast.fail({
                  message: res.message || '设置失败',
                  forbidClick: true,
                  duration: 1500
                })
              }
              window.localStorage.removeItem('productId')
            })
            .catch((err) => {
              dispatch('handleActionApi', {
                isp: CarrierType.ENUM[CarrierType.CUCC],
                token: rootState.app.userInfo.TOKEN,
                phone: rootState.app.userInfo.phone,
                action: '31',
                interfaceName: '/settingRingOnePointMon',
                resCode: 'err',
                resMessage: JSON.stringify(err)
              })
            })
        } else {
          const query = {
            ...Router.currentRoute.query,
            ...state.settingData
          }
          if (Number(Router.currentRoute.query.status_height) > 0) {
            query.status_height = Router.currentRoute.query.status_height
          }
          if (openNew) {
            delete query.header_type
            delete query.status_height
            query.tabType = 'tab-home'
            deeplinkto('/verification-code', query)
          } else {
            Router.push({
              name: 'verification-code',
              query,
              params: {
                isPush: true
              }
            })
          }
        }
      }
    })
  }
}
