import crypto from '@/utils/crypto'
function setupWebViewJavascriptBridge (callback) {
  if (window.WebViewJavascriptBridge) {
    callback(window.WebViewJavascriptBridge)
  } else {
    document.addEventListener(
      'WebViewJavascriptBridgeReady',
      function () {
        if (window.onWebViewJavascriptBridgeReady) {
          window.onWebViewJavascriptBridgeReady(
            (window.__bridge = window.WebViewJavascriptBridge)
          )
        }
        callback(window.WebViewJavascriptBridge)
      },
      false
    )
  }
}
// 10.9.0判断从别的页面返回
export function onResume (fn) {
  getBridge.then(bridge => {
    bridge.registerHandler('onResume', fn)
  })
}
export const getBridge = new Promise((resolve, reject) => {
  setupWebViewJavascriptBridge(function (bridge) {
    bridge.init()
    resolve(bridge)
  })
})
// 获取客户端暗黑模式
export function getClientDarkMode () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'dark',
        {},
        function (darkMode) {
          resolve(darkMode)
        }
      )
    })
  })
}
// 发现音乐
export function foundMusic () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'foundMusic',
        {},
        function (darkMode) {
          resolve(darkMode)
        }
      )
    })
  })
}
// 注册获取图片回调
export function registerAcquirePictureCallback (fn) {
  getBridge.then(bridge => {
    bridge.registerHandler('acquirePictureCallback', fn)
  })
}
// 上传大文件回调
export function uploadFileCallback (fn) {
  getBridge.then(bridge => {
    bridge.registerHandler('uploadFileCallback', fn)
  })
}
// 监听输入框键盘
export function KeyboardUp () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'hasKeyboard',
        {},
        function (response) {
          resolve(response)
        }
      )
    })
  })
}

export function uiControl (transparentNavigation) {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'uiControl', {
          transparentNavigation: transparentNavigation
        },
        function (response) {
          resolve(response)
        }

      )
    })
  })
}

// 返回上一页
export function finishPage () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'finishPage',
        {},
        function (response) {
          resolve(response)
        }
      )
    })
  })
}
// 获取头像
export function getAvatarUrl () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'accountInfo',
        {},
        function (darkMode) {
          resolve(darkMode)
        }
      )
    })
  })
}
// 退出webview
export function exitPage () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'exit',
        {},
        function (response) {
          resolve(response)
        }
      )
    })
  })
}

export function decryptBridge (data) {
  const response = JSON.parse(data)
  if (response.decoders) {
    response.decoders.split(',').forEach(key => {
      response[key] = crypto.byteBase64(response[key])
    })
    delete response.decoders
  }
  return response
}

export function getUserInfo () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'getUserAuth',
        {},
        function (responseData) {
          const data = JSON.stringify(decryptBridge(responseData))
          resolve(data)
        }
      )
    })
  })
}
export function launchDialog () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'launchHeytapDialog',
        {},
        function (responseData) {
          resolve(responseData)
        }
      )
    })
  })
}
export function launchHTDialog () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'launchHTDialog',
        {},
        function (responseData) {
          resolve(responseData)
        }
      )
    })
  })
}
// 查询客户端是否安装微信
export function isWXAppInstalled () {
  return new Promise((resolve, reject) => {
    getBridge.then(bridge => {
      window.WebViewJavascriptBridge.callHandler(
        'wxInstalled',
        {},
        function (responseData) {
          resolve(responseData)
        }
      )
    })
  })
}
// 是否支持选择支付方式
export function supportSelectPayType () {
  return new Promise((resolve, reject) => {
    getBridge.then(bridge => {
      window.WebViewJavascriptBridge.callHandler(
        'supportSelectPayType',
        {},
        function (responseData) {
          resolve(responseData)
        }
      )
    })
  })
}
// 获取minibar播放器状态 10.2
export function getPlayerState (data) {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'getPlayerState',
        data,
        function (responseData) {
          resolve(responseData)
        }
      )
    })
  })
}
// 自动发送播放器状态
export function registerPayCallback (fn) {
  getBridge.then(bridge => {
    bridge.registerHandler('onPayCallback', fn)
  })
}
export function h5DarkChange (fn) {
  getBridge.then(bridge => {
    bridge.registerHandler('h5Dark', fn)
  })
}
// ============10.3=================
export function login () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'login',
        {},
        function (responseData) {
          resolve(responseData)
        }
      )
    })
  })
}
export function onLoginCallback (fn) {
  getBridge.then(bridge => {
    bridge.registerHandler('onLoginCallback', fn)
  })
}

export function collectCallback (fn) {
  getBridge.then(bridge => {
    bridge.registerHandler('collectCallback', fn)
  })
}
export function launchHTDialog2 () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'launchHTDialog2',
        {},
        function (responseData) {
          resolve(responseData)
        }
      )
    })
  })
}
export function launchHTDialogCallback (fn) {
  getBridge.then(bridge => {
    bridge.registerHandler('launchHTDialogCallback', fn)
  })
}
// 注册获取 歌曲播放状态回调
export function onPlayCallback (fn) {
  getBridge.then(bridge => {
    bridge.registerHandler('onPlayCallback', fn)
  })
}
// 歌曲播放错误
export function onPlayErrorCallback (fn) {
  getBridge.then(bridge => {
    bridge.registerHandler('onPlayErrorCallback', fn)
  })
}
// 调起minibar
export function launchPlay (type, id, referrer, openMiniPlayerBar, jumpToPlayer, playlistType, playlistId, playlistName) {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'launchPlay', {
          resourceId: id,
          type: type,
          referrer: referrer,
          openMiniPlayerBar: openMiniPlayerBar,
          jumpToPlayer: jumpToPlayer,
          playlist_type: playlistType,
          playlist_id: playlistId,
          playlist_name: playlistName
        },
        function (response) {
          resolve(response)
        }

      )
    })
  })
}

// 查询minibar状态
export function openMiniPlayer (data) {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'openMiniPlayer', data,
        function (response) {
          resolve(response)
        }
      )
    })
  })
}

// 查询minibar状态
export function isShowMiniPlayerBar () {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'isShowMiniPlayerBar', {},
        function (response) {
          resolve(response)
        }
      )
    })
  })
}

// 页面保存状态（App->H5)
export function savePageState (data) {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'savePageState', data,
        function (response) {
          resolve(response)
        }

      )
    })
  })
}
// 页面恢复状态（App->H5)
export function restorePageState (data) {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'restorePageState', data,
        function (response) {
          resolve(response)
        }
      )
    })
  })
}

// 文本翻译（App->H5)
export function getString (data) {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'getString', data,
        function (response) {
          resolve(response)
        }

      )
    })
  })
}
export function toggleCollectSong (songId, isCollect) {
  return new Promise((resolve, reject) => {
    getBridge.then((bridge) => {
      window.WebViewJavascriptBridge.callHandler(
        'toggleCollectSong', {
          songId: songId,
          isCollect: isCollect
        },
        function (response) {
          resolve(response)
        }

      )
    })
  })
}
