import sourceApi from '@/api/modules/source'
import CarrierType from '@/model/CarrierType'
const state = {
  selfInfo: {
    uid: '',
    token: '',
    'dev-brand': '',
    'dev-manufacturer': '',
    'dev-model': '',
    'dev-language': '',
    'dev-region': '',
    'dev-timezone': '',
    'dev-app-version': '',
    'dev-channel': '',
    'dev-package': '',
    'dev-id': '',
    'dev-pid': ''
  },
  cookieId: '',
  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
  loginType: '',
  HTisLogin: false,
  darkbg: undefined,
  darkColor: '',

  loginStatus: !!localStorage.getItem('userInfo'), // 取得到说明已经确认登录，取不到false
  codeValidate: false,
  backIconWhite: true,
  configData: {},
  processConfig: {

  },
  isAllowedPrivacy: false
}

const mutations = {
  SET_DETAIL_STYLE (state, showDetailStyle) {
    state.showDetailStyle = showDetailStyle
  },
  SET_IS_ALLOWED_PRIVACY (state, isAllowedPrivacy) {
    state.isAllowedPrivacy = isAllowedPrivacy
  },
  SET_BACK_ICON_WHITE (state, backIconWhite) {
    state.backIconWhite = backIconWhite
  },
  SET_COOKIEID (state, cookieId) {
    state.cookieId = cookieId
  },
  SET_SELFINFO: (state, selfInfo) => {
    state.selfInfo = selfInfo
  },
  SET_DARKBG: (state, darkbg) => {
    state.darkbg = darkbg
  },
  SET_DARKCOLOR: (state, darkColor) => {
    state.darkColor = darkColor
  },
  SET_USERINFO: (state, res) => {
    state.userInfo = res
  },
  // 如果是个人中心已经登陆或详情登录成功 personal，否则detail
  SET_LOGIN_TYPE: (state, data) => {
    state.loginType = data
  },
  // 是否真正登录
  SET_LOGIN_STATUS: (state, data) => {
    state.loginStatus = !!data
  },
  // 验证码是否验证通过
  SET_CODE_VALIDATE: (state, data) => {
    state.codeValidate = !!data
  },
  SET_TOKEN: (state, TOKEN) => {
    state.userInfo.TOKEN = TOKEN
  },
  SET_HUAN_TAI_ISLOGIN (state, isLogin) {
    state.HTisLogin = isLogin
  },
  SET_CONFIG_DATA (state, data) {
    state.configData = Object.assign({}, state.configData, data)
    try {
      // 有功能002111H
      if (state.configData.ring_mobile_channel1) {
        state.configData.ring_mobile_channel1 = JSON.parse(state.configData.ring_mobile_channel1)
      }
      // 无功能002111G
      if (state.configData.ring_mobile_channel2) {
        state.configData.ring_mobile_channel2 = JSON.parse(state.configData.ring_mobile_channel2)
      }

      // 有功能00211E7
      if (state.configData.ring_mobile_channel3) {
        state.configData.ring_mobile_channel3 = JSON.parse(state.configData.ring_mobile_channel3)
      }

      // 有功能00211E8
      if (state.configData.ring_mobile_channel4) {
        state.configData.ring_mobile_channel4 = JSON.parse(state.configData.ring_mobile_channel4)
      }
    } catch (err) {
      console.log(err)
    }
  },
  SET_PROCESS_CONFIG_DATA (state, data) {
    state.processConfig = data
    Object.assign({
      1: {
        page: false,
        dialog: false,
        rules: ''
      },
      2: {
        page: false,
        dialog: false,
        rules: ''
      },
      3: {
        page: false,
        dialog: false,
        rules: ''
      }
    }, data)
  }
}

const actions = {
  getConfigPage ({ commit }) {
    return new Promise((resolve, reject) => {
      sourceApi
        .queryConfig()
        .then((res) => {
          commit('SET_CONFIG_DATA', res.data)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getProcessConfigPage ({ commit }) {
    return new Promise((resolve, reject) => {
      sourceApi
        .queryRule()
        .then((res) => {
          console.log(res)

          const data = {
            1: {},
            2: {},
            3: {}
          }
          for (const i of res.data) {
            console.log(i)
            if (i.sourceType === Number(CarrierType.MIGU)) {
              if (JSON.stringify(data[CarrierType.MIGU]) === '{}') {
                data[CarrierType.MIGU].page = i.pageStatus === 1
                data[CarrierType.MIGU].dialog = i.popupStatus === 1
                data[CarrierType.MIGU].rules = i.pageContent
              } else {
                continue
              }
            } else if (i.sourceType === Number(CarrierType.CUCC)) {
              if (JSON.stringify(data[CarrierType.CUCC]) === '{}') {
                data[CarrierType.CUCC].page = i.pageStatus === 1
                data[CarrierType.CUCC].dialog = i.popupStatus === 1
                data[CarrierType.CUCC].rules = i.pageContent
              } else {
                continue
              }
            } else if (i.sourceType === Number(CarrierType.TELECOM)) {
              if (JSON.stringify(data[CarrierType.TELECOM]) === '{}') {
                data[CarrierType.TELECOM].page = i.pageStatus === 1
                data[CarrierType.TELECOM].dialog = i.popupStatus === 1
                data[CarrierType.TELECOM].rules = i.pageContent
              } else {
                continue
              }
            }
          }
          commit('SET_PROCESS_CONFIG_DATA', data)
          console.log(data)
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
