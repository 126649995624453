import CryptoJS from 'crypto-js'
import { JSEncrypt } from 'jsencrypt'

export default {
  encrypt: function (word) {
    word = typeof word === 'string' ? word : JSON.stringify(word)
    const keyStr = CryptoJS.lib.WordArray.random(8).toString()
    const params = this.aes(this.aes(word), keyStr)
    return {
      encSecKey: this.rsa(keyStr),
      params: params
    }
  },
  aes: function (word, keyStr) {
    keyStr = keyStr || 'yLgLkLYMMmWFpcFc'
    const key = CryptoJS.enc.Utf8.parse(keyStr)
    const srcs = CryptoJS.enc.Utf8.parse(word)
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
  },
  rsa: function (word, keyStr) {
    keyStr = keyStr || 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQktqNf1amHk8C/ba40hZFx36zJbcksY3zICo+DFG2n7sH/mlUsJZUNoHt6hIcorgVTS42Fk6aWUWpvJqnFdtaq//HyIPdwtgW29QQNxA3LjPB4fab4h4JjRCxPOE3kVzTkznRO9AnRGWrXfWLJJJdLFXrNSS6N6Jonee6Q6kRSQIDAQAB'
    const encrypt = new JSEncrypt({ log: true })
    encrypt.setPublicKey(keyStr)
    return encrypt.encrypt(word)
  },
  // 解密
  decrypt (word, keyStr) {
    keyStr = keyStr || 'HsxXNEyweafpmkCW'
    const key = CryptoJS.enc.Utf8.parse(keyStr)
    const decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
  },
  decrypt1 (word, key) {
    // eslint-disable-next-line no-var, no-redeclare
    var key = CryptoJS.enc.Utf8.parse(key)
    const base64 = CryptoJS.enc.Base64.parse(word)
    const src = CryptoJS.enc.Base64.stringify(base64)
    // 解密模式为CBC，补码方式为PKCS5Padding（也就是PKCS7）
    const decrypt = CryptoJS.AES.decrypt(src, key, {
      iv: key, // 偏移量
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })

    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    return decryptedStr.toString()
  },
  base64 (base64) {
    const decrypt = CryptoJS.enc.Base64.parse(base64)
    return decrypt.toString(CryptoJS.enc.Utf8)
  },
  byteBase64 (data) {
    const decodedData = decodeURIComponent(data)
    const decodedBytes = Uint8Array.from(atob(decodedData), c => c.charCodeAt(0))
    const decryptedBytes = decodedBytes.map(byte => byte ^ 5)
    const decryptedString = new TextDecoder().decode(decryptedBytes)
    return decryptedString
  },

  // 数字签名 一键登录所使用的签名
  generateMacSignature (secret, data) {
    const hash = CryptoJS.HmacSHA256(data, secret)
    const byteHMAC = CryptoJS.enc.Base64.stringify(hash)
    return byteHMAC
  },
  aesCbc256encrypt (secretKey, ivStr, data) {
    try {
      const key = CryptoJS.enc.Utf8.parse(secretKey)
      const iv = CryptoJS.enc.Utf8.parse(ivStr)
      const encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Iso10126
      })
      return encrypted.toString()
    } catch (e) {
      return null
    }
  },
  aesCbc256decrypt (secretKey, ivStr, encryptedData) {
    try {
      const key = CryptoJS.enc.Utf8.parse(secretKey)
      const iv = CryptoJS.enc.Utf8.parse(ivStr)
      const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Iso10126
      })
      return decrypted.toString(CryptoJS.enc.Utf8)
    } catch (e) {
      return null
    }
  }

}
