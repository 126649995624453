const getters = {
  selfInfo: state => state.app.selfInfo,
  userInfo: state => state.app.userInfo,
  isUserInfoHasValue: state => {
    return JSON.stringify(state.app.userInfo) !== '{}'
  },
  darkbg: state => state.app.darkbg,
  darkColor: state => state.app.darkColor,
  HTisLogin: state => state.app.HTisLogin
}
export default getters
