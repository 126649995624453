// 接口管理
const interfaces = {
  login: '/login',
  sendMsg: '/sendMsg',
  queryBanner: '/queryBanner',
  queryCategory: '/queryCategory',
  queryCategoryVideos: '/queryCategoryVideos',
  queryVideoByCategoryId: '/queryVideoByCategoryId',
  queryVideo: '/queryVideo',
  queryQa: '/queryQa',
  settingRingOnePointMon: '/settingRingOnePointMon',
  getOrderList: '/getOrderList',
  unSubProductWithVCode: '/unSubProductWithVCode',
  delOrder: '/delOrder',
  sendVerifyCode: '/sendVerifyCode',
  qrySubedProducts: '/qrySubedProducts',
  queryRankSongList: '/api/v2/h5/rankSongList',
  favoriteSongList: '/api/v2/h5/ringtong/favorite/list',
  deleteFavoriteSong: '/api/v2/h5/ringtong/favorite/delete',
  openOrder: '/telecom/openOrder',
  packageList: '/telecom/package/list',
  addtoneFree: '/telecom/addtone/free',
  orderDetail: '/telecom/order/detail',
  checkMobile: '/checkMobile',
  loginSecret: '/mobile/login/secret',
  ringStatus: '/mobile/ring/status',
  monthStatusV1: '/mobile/month/status/v1',
  mobileRingOpen: '/mobile/ring/open',
  monthStatusV2: '/mobile/month/status/v2',
  toneFreeOrderV1: '/mobile/tone/free/order/v1',
  toneFreeOrderV2: '/mobile/tone/free/order/v2',
  ringCallBack: '/mobile/ring/callback',
  getUserPhone: '/v1/user/phone',
  queryIntroduce: '/queryIntroduce',
  newLogin: '/new/login',
  searchHints: '/search/hints',
  searchHots: '/search/hots',
  searchpages: '/search/searchpages',
  searchDetailById: '/search/searchDetail',
  searchRel: '/search/searchRel',
  channeladd: '/channel/add',
  actionlog: '/actionlog/add2',
  queryConfig: '/config/query',
  queryRule: '/config/rule',
  queryTopicDraft: '/querySubjectDraft',
  queryTopic: '/querySubject',
  queryRingRel: '/queryRingRel',
  queryRandom: '/queryRandom',
  qrySubedProductsNoToken: '/unicom/qrySubedProductsNoToken', // 查询
  settingRingOnePointMon2: '/unicom/settingRingOnePointMon', // 设置
  onePointProductMon: '/unicom/onePointProductMon', // 开通
  queryHome: '/queryHome'
}

export default interfaces
