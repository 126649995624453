import axios from 'axios'
import { Toast } from 'vant'
import { getUserInfo } from '@/utils/bridge.js'
require('es6-promise').polyfill()
const instance = axios.create({
  timeout: 1000 * 12,
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SRC3
      : process.env.VUE_APP_SRC3
})
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
getUserInfo().then((data) => {
  const userInfo = JSON.parse(data)
  instance.defaults.headers.uid = userInfo.uid
  instance.defaults.headers.token = userInfo.token
  instance.defaults.headers['dev-brand'] = userInfo['dev-brand']
  instance.defaults.headers['dev-model'] = userInfo['dev-model']
  instance.defaults.headers['dev-manufacturer'] = userInfo['dev-manufacturer']
  instance.defaults.headers['dev-app-version'] = userInfo['dev-app-version']
  instance.defaults.headers['dev-build-version'] = userInfo['dev-build-version']
  instance.defaults.headers['dev-language'] = userInfo['dev-language']
  instance.defaults.headers['dev-region'] = userInfo['dev-region']
  instance.defaults.headers['dev-timezone'] = userInfo['dev-timezone']
  instance.defaults.headers['dev-channel'] = userInfo['dev-channel']
  instance.defaults.headers['dev-pid'] = userInfo['dev-pid']
  instance.defaults.headers['dev-id'] = userInfo['dev-id']
  instance.defaults.headers['dev-package'] = userInfo['dev-package']
  instance.defaults.headers['dev-distinct-id'] = userInfo['dev-distinct-id']
})
instance.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      switch (response.data.code) {
        case '3001':
          Toast({
            message: '登录过期，请重新登录',
            duration: 1000,
            forbidClick: true
          })
          break
      }
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          break
        case 403:
          Toast({
            message: '登录过期，请重新登录',
            duration: 1000,
            forbidClick: true
          })
          break
        case 404:
          Toast({
            message: '网络请求不存在',
            duration: 1500,
            forbidClick: true
          })
          break
        default:
          Toast({
            message: error.response.data.message,
            duration: 1500,
            forbidClick: true
          })
      }
      return Promise.reject(error.response)
    }
  }
)
export default instance
